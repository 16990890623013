import { Loader, Tabs } from "@mantine/core";
import { useFunctionsQuery } from "@react-query-firebase/functions";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ModalEditAdmin from "../components/Modals/ModalEditAdmin";
import ModalWhitelistUser from "../components/Modals/ModalWhitelistUser";
import TableDomains from "../components/Views/_settings/TableDomains";
import TableUsers from "../components/Views/_settings/TableUsers";
import { functions } from "../firebase";
import ModalWhitelistDomain from "../components/Modals/ModalWhitelistDomain";
import {
  useFirestore,
  useFirestoreDocData,
  useFirestoreCollectionData,
} from "reactfire";
import {
  doc,
  setDoc,
  collection,
  addDoc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";
const AccessControlPage = () => {
  const { projectId } = useParams();

  const firestore = useFirestore();
  const projectRef = doc(firestore, "projects", projectId);
  const emailsAclRef = collection(
    firestore,
    `projects/${projectId}/emails_acl`
  );
  const { status: emailAclsStatus, data: emailsAclData } =
    useFirestoreCollectionData(
      query(emailsAclRef, orderBy("timestamp", "desc")),
      { idField: "id" }
    );

  const { data: projectData, status: projectDataStatus } =
    useFirestoreDocData(projectRef);

  const [openWhitelistModalUser, setOpenWhitelistModalUser] = useState(false);
  const [openWhitelistModalDomain, setOpenWhitelistModalDomain] =
    useState(false);

  const [openEditAdminModal, setOpenEditAdminModal] = useState(false);
  const [editAdminData, setEditAdminData] = useState({});
  const [aclDomains, setAclDomains] = useState([]);

  useEffect(() => {
    if (Array.isArray(projectData?.domains_acl)) {
      setAclDomains(projectData?.domains_acl.map((domain) => ({ domain })));
    }
  }, [projectData?.domains_acl]);

  const addDomain = useCallback(
    (domain) => {
      let domains = [...aclDomains.map((entry) => entry.domain), domain];
      //make every item lower case and remove duplicates
      domains = [...new Set(domains.map((item) => item.toLowerCase()))];
      //save to firestore
      setDoc(projectRef, { domains_acl: domains }, { merge: true });
      setOpenWhitelistModalDomain(false);
    },
    [aclDomains]
  );

  const deleteDomain = useCallback(
    (domain) => {
      let domains = [...aclDomains.map((entry) => entry.domain)];
      //make every item lower case and remove duplicates
      domains = [...new Set(domains.map((item) => item.toLowerCase()))];
      //remove domain
      domains = domains.filter((item) => item !== domain.toLowerCase());
      //save to firestore
      setDoc(projectRef, { domains_acl: domains }, { merge: true });
    },
    [aclDomains]
  );

  const addEmailToAcl = async (name, email) => {
    const lowercaseEmail = email.toLowerCase();

    if (emailsAclData.some((entry) => entry.email === lowercaseEmail)) {
      // console.log(`Email ${lowercaseEmail} already exists in the collection.`);
      return false;
    } else {
      try {
        await addDoc(emailsAclRef, {
          name: name.trim(),
          email: lowercaseEmail,
          timestamp: Date.now(),
        });
        // console.log(`Email ${lowercaseEmail} added to the collection.`);
        setOpenWhitelistModalUser(false);
        return true;
      } catch (error) {
        console.error("Error adding document: ", error);
        return false;
      }
    }
  };

  const editEmailAcl = (data) => {
    const emailRef = doc(
      firestore,
      `projects/${projectId}/emails_acl`,
      data.id
    );
    setDoc(emailRef, {
      email: data.email.toLowerCase(),
      timestamp: Date.now(),
      name: data.name,
    });
    setOpenEditAdminModal(false);
  };

  const removeEmailAcl = (id) => {
    const emailRef = doc(firestore, `projects/${projectId}/emails_acl`, id);
    deleteDoc(emailRef);
  };

  // Get admins
  const { data: usersAdminsData, isLoading: usersAdminsLoading } =
    useFunctionsQuery(
      ["getProjectAdmins", projectId],
      functions,
      "project-onGetProjectAdmins",
      {
        projectId: projectId,
      }
    );

  const handleOpenEditModal = (name, email, photoUrl, id) => {
    setOpenEditAdminModal(true);
    setEditAdminData({ name, email, photoUrl, id });
  };
  const handleCloseEditModal = () => {
    setOpenEditAdminModal(false);
    setEditAdminData({});
  };

  return (
    <>
      <ModalWhitelistUser
        opened={openWhitelistModalUser}
        setOpened={setOpenWhitelistModalUser}
        addEmail={addEmailToAcl}
        emailsAclData={emailsAclData}
      />
      <ModalWhitelistDomain
        opened={openWhitelistModalDomain}
        setOpened={setOpenWhitelistModalDomain}
        addDomain={addDomain}
      />
      <ModalEditAdmin
        opened={openEditAdminModal}
        handleCloseEditModal={handleCloseEditModal}
        editAdminData={editAdminData}
        onSubmit={editEmailAcl}
      />

      {!usersAdminsLoading ? (
        <div className="mt-[50px] w-full px-6 pb-[56px]">
          <Tabs
            defaultValue="users"
            styles={{
              tabsList: {
                background: "#F2EFFE",
                borderRadius: "8px 8px 0px 0px",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
                columnGap: "10px",
              },
              tab: {
                color: "#1D123366",
                backgroundColor: "#D9D6EA !important",
                padding: "10.5px 24px",
                borderRadius: "8px 8px 0px 0px",

                "&[data-active]": {
                  background: "white !important",
                  color: "#5E37FF",
                },
              },
            }}
            variant="outline"
          >
            <Tabs.List>
              <Tabs.Tab value="users">Users</Tabs.Tab>
              <Tabs.Tab value="domains">Domains</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="users">
              <TableUsers
                headerBtnLabel="Whitelist User"
                headerBtnEvent={() => setOpenWhitelistModalUser(true)}
                disableSpacingTop={true}
                usersData={emailsAclData || []}
                setOpenEditAdminModal={setOpenEditAdminModal}
                handleOpenEditModal={handleOpenEditModal}
                deleteUser={removeEmailAcl}
              />
            </Tabs.Panel>

            <Tabs.Panel value="domains">
              <TableDomains
                headerBtnLabel="Add domain"
                headerBtnEvent={() => setOpenWhitelistModalDomain(true)}
                domainsData={aclDomains || []}
                setOpenEditAdminModal={setOpenEditAdminModal}
                handleOpenEditModal={handleOpenEditModal}
                deleteDomain={deleteDomain}
              />
            </Tabs.Panel>
          </Tabs>
        </div>
      ) : (
        <div className="flex items-center mt-32 justify-center w-full">
          <Loader />
        </div>
      )}
    </>
  );
};

export default AccessControlPage;
