import { Menu, Button } from "@mantine/core";
import "@mantine/core/styles.css";
import React, { useMemo } from "react";
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { useLocation } from "react-router-dom";
import ArrowDown from "../assets/icons/ArrowDown";
import LogoutIcon from "../assets/icons/LogoutIcon";
import CascadeIconResponsive from "../assets/icons/new/CascadeIconResponsive";
import MainLogo from "../assets/illustrations/MainLogo";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../firebase";

const Navbar = ({ asIntro }) => {
  const [userAuth] = useAuthState(auth);
  const [signOut] = useSignOut(auth);
  const revokeRefreshToken = httpsCallable(
    functions,
    "user-revokeRefreshToken"
  );

  const location = useLocation();

  const headTitle = useMemo(() => {
    if (location.pathname.split("/")[3] === "settings") {
      const title_settings = {
        admins: "Admins",
        "access-control": "Access control",
        "company-details": "Company details",
      };

      if (!location.pathname.split("/")[4]) {
        return "Settings";
      }

      return title_settings[location.pathname.split("/")[4]];
    } else {
      const title_rest = {
        files: "My files",
        chats: "Chats",
        charts: "Metrics",
      };

      return title_rest[location.pathname.split("/")[3]];
    }
  }, [location]);

  const handleSignOut = async () => {
    await revokeRefreshToken();
    await signOut();
  };

  const getUsername = useMemo(() => {
    if (!!userAuth.uid) {
      const userAuth_name =
        !!userAuth?.displayName?.trim().length && userAuth?.displayName;

      const userAuth_prov_name =
        !!userAuth?.providerData[0]?.displayName?.trim().length &&
        userAuth?.providerData[0]?.displayName;

      if (userAuth_prov_name || userAuth_name) {
        return userAuth_prov_name || userAuth_name;
      }

      return !!userAuth?.email?.length
        ? userAuth?.email
        : userAuth?.providerData[0]?.email;
    }
  }, [userAuth]);

  return (
    <div className="px-6 pt-[44px] flex items-start justify-between">
      <div className="flex flex-col pt-2">
        <span className="text-[#1D1233] text-3xl font-semibold">
          {asIntro ? (
            <>
              <div className="hidden cs_lg:block">
                <MainLogo />
              </div>
              <div className="block cs_lg:hidden">
                <CascadeIconResponsive />
              </div>
            </>
          ) : (
            headTitle
          )}
        </span>
      </div>

      <Menu
        width={200}
        styles={{ dropdown: { borderRadius: "16px" } }}
        withinPortal={true}
        trapFocus={false}
      >
        <Menu.Target>
          <div className="flex items-center cursor-pointer gap-x-2">
            {!userAuth.photoURL ? (
              <div className="rounded-full w-[40px] h-[40px] bg-gradient-to-r from-purple-500 to-pink-500"></div>
            ) : (
              <div className="rounded-full">
                <img
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  alt="profile-avatar"
                  className="rounded-[inherit] w-[40px] h-[40px]"
                  src={userAuth.photoURL}
                />
              </div>
            )}
            <div className="flex flex-col items-start">
              <div className="flex items-center">
                <span className="text-[#1D1233] text-base font-semibold">
                  {getUsername}
                </span>
                <div className="pb-1 pl-2">
                  <ArrowDown width={13} height={13} />
                </div>
              </div>
              <span className="text-[#777281] text-xs font-normal">
                Admin account
              </span>
            </div>
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<LogoutIcon width={14} height={14} />}
            onClick={handleSignOut}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default Navbar;
