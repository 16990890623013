import React from "react";
import Navbar from "../Layout/Navbar";
import PlusIconBordered from "../assets/icons/new/PlusIconBordered";
import { useDisclosure } from "@mantine/hooks";
import CreateCollectionModal from "../components/Modals/CreateCollectionModal";

const IntroPage = ({ refetchProjectData }) => {
  const [openedModal, toggleModal] = useDisclosure(false);

  return (
    <>
      <CreateCollectionModal
        projectsRefetch={refetchProjectData}
        close={toggleModal.close}
        opened={openedModal}
      />

      <div className="min-h-screen h-full w-full relative flex flex-col items-start overflow-hidden max-h-screen">
        <div className="w-full h-full flex-1">
          <Navbar asIntro={true} />
          <div className="mt-[76px] w-full flex flex-col items-center justify-center">
            <span className="text-4xl cs_lg:text-5xl font-semibold max-w-[360px] cs_lg:max-w-[460px] w-full text-center">
              Hooray and welcome aboard!
            </span>
            <span className="text-base text-center font-normal px-3 pt-6 max-w-[680px]">
              You've just unlocked a new dimension in AI-powered support.
              Welcome to our community of high-performing HR teams. We know this
              is your first time with us, so dive right in! Get started by
              creating your inaugural project. Just complete the form below, and
              voilà – you're set for success. Yes, elevating your HR game is
              that effortless. Ready to amplify your impact?
            </span>
            <div className="max-w-[292px] w-full mt-8">
              <button
                type="button"
                onClick={toggleModal.open}
                className="bg-[#5E37FF] flex w-full hover:bg-[#1D1233] transition-colors outline-none text-center rounded-full items-center gap-x-3 cs_lg:w-full h-auto py-3 font-semibold text-white justify-center"
              >
                Create your first project <PlusIconBordered />
              </button>
            </div>
          </div>
        </div>
        <div className="w-full mt-20 cs_lg:mt-[100px] relative">
          <video
            src="/sphere_down_q.mp4"
            width="100%"
            height="100%"
            className="mx-auto w-[85%] cs_lg:w-[100%]"
            loop
            autoPlay
            playsInline
            muted
          ></video>
        </div>
      </div>
    </>
  );
};

export default IntroPage;
