import React from "react";
import { Link, useParams } from "react-router-dom";

const Integrations = () => {
  const { projectId } = useParams();

  return (
    <div className="flex flex-col items-start mt-10">
      <span className="text-2xl font-semibold text-[#1D1233]">
        Integrations
      </span>
      <span className="text-base font-normal text-[#1D1233] pt-2">
        Link Cascade to platforms
      </span>

      <div className="flex flex-col w-full mt-4 pl-2">
        <span className="text-base font-normal text-[#1D1233] mt-1">Slack</span>

        <div className="mt-1">
          <Link
            rel="noreferrer noopener"
            target="_blank"
            className="py-[25px] w-[150px]"
            to={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=users.profile:read,users:read.email,app_mentions:read,chat:write,chat:write.customize,im:history,im:read,im:write,users:read,channels:history&user_scope=chat:write,channels:history&redirect_uri=${window.location.protocol}//${window.location.host}/slackbot/${projectId}`}
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Integrations;
