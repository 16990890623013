import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Layout/Navbar";
import Sidebar from "../Layout/Sidebar";
import mainBg from "../assets/illustrations/mainbg.svg";

const Dashboard = ({ projectsData, refetchProjectData }) => {
  return (
    <div className="min-h-screen h-full w-full relative flex items-start">
      <Sidebar
        projectsData={projectsData}
        refetchProjectData={refetchProjectData}
      />

      <div
        style={{ backgroundImage: `url("${mainBg}")` }}
        className="w-full h-full min-h-screen bg-cover bg-center flex-1"
      >
        <Navbar />
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
