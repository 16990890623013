import {useAuthState} from "react-firebase-hooks/auth";
import {useEffect, useState} from "react";
export const useEmailUUIDAuthState = (auth) => {
    const [emailUUID, setEmailUUID]  = useState();
    const authData = useAuthState(auth);
    useEffect(() => {
        const user = authData[0];
        if (user) {
            user.getIdToken().then(token => {
                const payloadBase64 = token.split('.')[1];
                const decodedPayload = JSON.parse(atob(payloadBase64));
                setEmailUUID(decodedPayload.email_uuid);
            });
        }
    }, [authData[0]]);

    return emailUUID;
}