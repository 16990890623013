import { Modal } from "@mantine/core";
import React, {useState} from "react";
import PlusIconBordered from "../../assets/icons/new/PlusIconBordered";

//todo: check if valid email;
//todo: add name input
const ModalWhitelistUser = ({ opened, setOpened, addEmail, emailsAclData }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emailExists, setEmailExists] = useState(false); 
  const [nameError, setNameError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }

  const handleAddEmail = () => {
    if (validateEmail(email)) {
      setIsValidEmail(true);
      const lowercaseEmail = email.toLowerCase();
      if (emailsAclData.some((entry) => entry.email === lowercaseEmail)) {
        setEmailExists(true);
      } else {
        setEmailExists(false);

        if (!name.trim()) {
          setNameError(true);
        } else {
          setNameError(false);
          addEmail(name, email);
          setName('');
          setEmail('');
        }
      }
    } else {
      setIsValidEmail(false);
      setEmailExists(false); 
      setNameError(false);
    }
  };

  const handleCloseModal = () => {
    setOpened(false);
    setEmail('');
    setName('');
    setIsValidEmail(true);
    setEmailExists(false);
    setNameError(false);
  }

  return (
    <Modal
      closeOnClickOutside={false}
      centered
      withCloseButton={false}
      opened={opened}
      size={560}
      radius="md"
    >
      <div className="w-full flex justify-end">
        <button
          onClick={handleCloseModal}
          className="text-[#777281] text-[14px] text-right p-1 bg-transparent outline-none font-normal"
        >
          Close
        </button>
      </div>
      <div className="w-full flex-col flex items-center justify-center mt-6 px-[44px] pb-[44px]">
        <span className="text-[30px] font-semibold text-center w-full text-[#1D1233]">
          Whitelist User
        </span>
        <div className="mt-10 w-full">
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
          className={`border text-[#1D1233] text-base font-normal w-full outline-none rounded-full bg-transparent py-4 px-5 mb-5 ${
            isValidEmail ? (emailExists ? 'border-red-500' : '#C0BEC3') : 'border-red-500'
          }`}
        />
        {nameError && (
          <p className="text-red-600 text-sm mb-2">Name cannot be empty.</p>
        )}
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@eg.com"
            className={`border text-[#1D1233] text-base font-normal w-full outline-none rounded-full bg-transparent py-4 px-5 ${
              isValidEmail ? (emailExists ? 'border-red-500' : '#C0BEC3') : 'border-red-500'
            }`}
          />
          {!isValidEmail && (
            <p className="text-red-600 mt-1 text-sm">Please enter a valid email address.</p>
          )}
          {emailExists && (
            <p className="text-red-600 mt-1 text-sm">Email already exists.</p>
          )}
          <button
            onClick={handleAddEmail}
            className="mt-5 bg-[#5E37FF] text-white flex items-center justify-center gap-x-3 py-3 w-full rounded-full"
          >
            <span className="pt-1">Add User</span>
            <PlusIconBordered />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWhitelistUser;