import React from "react";

const StackedProjects = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_464_3425)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8455 0.174981C12.2524 -0.0583269 12.7476 -0.0583269 13.1545 0.174981L21.8046 5.14812C22.233 5.39482 22.5 5.86367 22.5 6.37271C22.5 6.88174 22.233 7.34947 21.8046 7.59729L13.1545 12.5693C12.7476 12.8037 12.2524 12.8037 11.8455 12.5693L3.19545 7.59729C2.76698 7.34947 2.5 6.88174 2.5 6.37271C2.5 5.86367 2.76698 5.39482 3.19545 5.14812L11.8455 0.174981ZM4.39579 6.37271L12.5 11.031L20.6042 6.37271L12.5 1.71325L4.39579 6.37271Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.46485 12.0156L12.5002 16.636L20.5355 12.0156L17.0238 10.2005C16.6255 9.994 16.4629 9.49055 16.6621 9.07752C16.8612 8.66337 17.3468 8.49481 17.7451 8.70132L21.7068 10.7509C22.1557 10.9831 22.4431 11.453 22.4539 11.9732C22.4646 12.4923 22.1966 12.9757 21.7584 13.2279L13.1547 18.1743C12.7478 18.4076 12.2526 18.4076 11.8456 18.1743L3.2419 13.2279C2.80375 12.9757 2.53569 12.4923 2.54646 11.9732C2.55722 11.453 2.84466 10.9831 3.29358 10.7509L7.25525 8.70132C7.65357 8.49481 8.13909 8.66337 8.33825 9.07752C8.53741 9.49055 8.37485 9.994 7.97653 10.2005L4.46485 12.0156Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.46363 17.6664L12.5 22.2867L20.5364 17.6664L17.0236 15.8468C16.6253 15.6403 16.4628 15.1368 16.6619 14.7227C16.8611 14.3096 17.3466 14.1422 17.7449 14.3487L21.7077 16.4016C22.1566 16.6338 22.444 17.1037 22.4548 17.6239C22.4656 18.143 22.1975 18.6264 21.7594 18.8776L13.1546 23.825C12.7476 24.0583 12.2524 24.0583 11.8455 23.825L3.24068 18.8776C2.80253 18.6264 2.53447 18.143 2.54523 17.6239C2.556 17.1037 2.84344 16.6338 3.29235 16.4016L7.25511 14.3487C7.65343 14.1422 8.13895 14.3096 8.33811 14.7227C8.53727 15.1368 8.37471 15.6403 7.97639 15.8468L4.46363 17.6664Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_464_3425">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StackedProjects;
