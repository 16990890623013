import React, { useState } from "react";
import LogoVertical from "../assets/illustrations/LogoVertical";

const VerificationEmail = ({ email, onVerifyEmail }) => {
  const [loading, setLoading] = useState(false);
  const onSubmitVerifyEmail = () => {
    setLoading(true);

    onVerifyEmail();
  };

  return (
    <div className="flex items-center gap-x-[60px] h-full w-full">
      <div className="flex-1 pl-[10%]">
        <div className="flex flex-col max-w-[460px] w-fulljustify-center items-center">
          <LogoVertical />
          <div className="mt-[60px] flex flex-col gap-y-3.5 text-[#1D1233] text-center">
            <h2 className="font-semibold text-[48px] leading-[58px]">
              Verify your email address
            </h2>
            <span className="font-normal text-base">
              You've entered {email} as the email address for your account.
              Please verify this email address by clicking button below.
            </span>
          </div>
          <button
            disabled={loading}
            onClick={onSubmitVerifyEmail}
            className="bg-[#5E37FF] w-full mt-10 disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
          >
            Verify your email
          </button>
        </div>
      </div>
      <div className="flex-1 min-h-screen relative overflow-hidden h-full">
        <video
          src="/sphere_side_t.mp4"
          width="100%"
          height="100%"
          className="absolute top-1/2 w-full min-h-screen transform -translate-y-1/2 -right-[0%]"
          loop
          autoPlay
          playsInline
          muted
        ></video>
      </div>
    </div>
  );
};

export default VerificationEmail;
