import { Accordion, CheckIcon, Loader, Notification } from "@mantine/core";
import {
  useFirestoreQuery,
  useFirestoreInfiniteQuery,
  useFirestoreDocument,
} from "@react-query-firebase/firestore";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CopyIcon from "../assets/icons/new/CopyIcon";
import ThumbsUpIcon from "../assets/icons/ThumbsUpIcon";
import ThumbsDownIcon from "../assets/icons/ThumbsDownIcon";
import MinusIconBordered from "../assets/icons/new/MinusIconBordered";
import PlusIconBordered from "../assets/icons/new/PlusIconBordered";
import RefreshIcon from "../assets/icons/new/RefreshIcon";
import SelectSearch from "../components/subcomponents/SelectSearch";
import Feedback from "../components/subcomponents/Feedback";
import FeedbackMessageAccordion from "../components/subcomponents/FeedbackMessageAccordion";
import { firestore } from "../firebase";

const ChatsPage = () => {
  const { projectId } = useParams();
  const [accordionIdOpened, setAccordionIdOpened] = useState(null);
  const [listItems, setListItems] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [loadingNext, setLoadingNext] = useState(false);
  const [availableRegions, setAvailableRegions] = useState([]);
  const [region, setRegion] = useState();
  const [feedback, setFeedback] = useState();

  const { data: projectData, isLoading: projectLoading } = useFirestoreDocument(
    ["projects", projectId],
    doc(firestore, "projects", projectId)
  );

  useEffect(() => {
    const available_regions = projectData?.data()?.regions || {};
    setAvailableRegions([
      ...Object.keys(available_regions).map((region) => ({
        label: available_regions[region],
        value: region,
      })),
    ]);
  }, [projectData]);

  const messagesQuery = useMemo(() => {
    let initialQuery = query(
      collection(firestore, "projects", projectId, "messages"),
      orderBy("date", "desc"),
      limit(10)
    );
    if (region) {
      initialQuery = query(initialQuery, where("region", "==", region));
    }

    if (feedback) {
      initialQuery = query(initialQuery, where("feedback", "==", feedback));
    }
    return initialQuery;
  }, [region, projectId, feedback]);

  const {
    data: messagesData,
    isLoading: messagesLoading,
    isRefetching: messagesIsRefetching,
    refetch: messagesRefetch,
    fetchNextPage,
    hasNextPage,
  } = useFirestoreInfiniteQuery(
    ["messagesContent", projectId, region, feedback],
    messagesQuery,
    (snapshot) => {
      if (snapshot.docs.length !== 10) {
        return null;
      }

      const lastDocument = snapshot.docs[snapshot.docs.length - 1];

      if (!lastDocument) {
        return null;
      }
      // Get the next 10 documents starting after the last document fetched.
      return query(messagesQuery, startAfter(lastDocument));
    }
  );

  const doRefetch = async () => {
    setListItems([]);
    await messagesRefetch();
  };

  const getNextData = async () => {
    if (hasNextPage) {
      await fetchNextPage();
    }
  };

  useEffect(() => {
    if (!messagesLoading && messagesData?.pages) {
      const offset = listItems.length;
      let allMessages = [];
      for (let page of messagesData.pages) {
        const data = page?.docs?.map((i) => ({ ...i.data(), id: i.id })) || [];
        allMessages = [...allMessages, ...data];
      }
      allMessages = allMessages.map((entry) => ({
        ...entry,
        initial: entry.date,
        format: typeof entry.date,
        date:
          typeof entry.date === "string"
            ? entry.date
            : new Date(
                entry.date.seconds * 1000 + entry.date.nanoseconds / 1000000
              ).toISOString(),
      }));
      setListItems(allMessages);
    }
  }, [messagesData, messagesLoading]);

  const [copied, setCopied] = useState(false);
  const handleCopySID = async (copy) => {
    await navigator.clipboard.writeText(copy).then(() => {
      setCopied(true);
    });
  };

  useEffect(() => {
    if (copied) {
      const t = setTimeout(() => {
        setCopied(false);
      }, 1500);

      return () => {
        clearTimeout(t);
      };
    }
  }, [copied]);

  return (
    <>
      <Notification
        styles={{
          root: {
            position: "fixed",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 100,
            opacity: copied ? 1 : 0,
            transition: "opacity 300ms ease",
          },
        }}
        icon={<CheckIcon width={12} height={12} />}
        withBorder
        withCloseButton={false}
      >
        Copied to clipboard!
      </Notification>

      <div className="mt-[50px] w-full px-6">
        <div class="flex flex-row items-center justify-between">
          <span className="text-2xl font-semibold text-[#1D1233]">
            Conversations
          </span>

          <div class="flex flex-row items-center justify-end gap-4">
            <SelectSearch
              label="Filter by feedback"
              data={[
                { value: "like", label: "Liked" },
                { value: "dislike", label: "Disliked" },
              ]}
              value={feedback}
              onChange={setFeedback}
            />
            <SelectSearch
              label="Filter by region"
              data={availableRegions}
              value={region}
              onChange={setRegion}
            />
          </div>
        </div>

        {!messagesLoading ? (
          <div className="flex flex-col w-full">
            <div className="mt-[32px] styled-scrollbar overflow-y-scroll max-h-[calc(100vh-296px)] h-full w-full bg-white rounded-lg px-0 flex flex-col gap-y-[48px]">
              {!!listItems.length ? (
                <Accordion
                  onChange={(e) => setAccordionIdOpened(e)}
                  chevronPosition="right"
                  chevron={<></>}
                  styles={{
                    chevron: {
                      width: "32px",
                      height: "32px",
                      display: "none",
                    },
                    panel: {
                      marginLeft: "0px",
                      marginRight: "0px",
                    },
                    label: {
                      padding: 0,
                    },
                    content: {
                      padding: 0,
                    },
                    control: {
                      paddingLeft: 0,
                      ":hover": {
                        background: "transparent",
                      },
                    },
                  }}
                  className="w-full h-full"
                  classNames={{
                    label: "w-full",
                  }}
                >
                  {listItems.map((item, index) => (
                    <Accordion.Item
                      key={index}
                      value={item.id || item.sid}
                      className="w-full border-b border-[#FAF9FF] py-6"
                    >
                      <Accordion.Control className="w-full flex flex-row items-center justify-start">
                        <div className="w-full flex flex-row items-center justify-start pl-6 pr-2">
                          <div className="flex flex-col w-full flex-1 flex-grow items-start">
                            <span className="text-[#5E37FF] text-xs font-semibold uppercase leading-[26px]">
                              Question
                            </span>
                            <span className="text-[#1D1233] text-base font-normal leading-[26px]">
                              {item.question}
                            </span>
                            <div className="flex flex-row items-center justify-start">
                              {item.region != null ? (
                                <>
                                  <span className="text-[#1D1233]/40 text-sm font-normal leading-[26px]">
                                    Region
                                  </span>

                                  <span className="text-white bg-[#7C6EFF] text-xs font-normal uppercase px-4 rounded-xl ml-2 mr-4 h-5 leading-0 pt-[2px] inline-flex flex-row items-center justify-center align-text-bottom font-semibold">
                                    {item.region}
                                  </span>
                                </>
                              ) : null}

                              <span className="text-[#1D1233]/40 text-sm font-normal leading-[26px]">
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }).format(new Date(item.date))}
                              </span>
                            </div>
                          </div>
                          <button
                            className={`cursor-pointer outline-none ${
                              accordionIdOpened === item.id
                                ? "bg-[#1D1233]"
                                : "bg-[#5E37FF]"
                            } rounded-full w-[32px] h-[32px] flex items-center justify-center`}
                            type="button"
                          >
                            {accordionIdOpened === item.id ? (
                              <MinusIconBordered width={25} height={25} />
                            ) : (
                              <PlusIconBordered width={20} height={20} />
                            )}
                          </button>
                        </div>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <div className="w-full pl-16 mt-6 pt-0 pr-6  broder-t border-t border-t-[#FAF9FF]">
                          <div className="flex flex-col gap-y-5 w-full pt-6">
                            <div className="flex flex-col w-full">
                              <span className="text-[#2BCC58] text-xs font-semibold uppercase leading-[26px]">
                                Session chat id
                              </span>
                              <button
                                type="button"
                                onClick={() => handleCopySID(item.sid)}
                                className="border outline-none w-fit flex items-center gap-x-2 border-[#E8E5F6] rounded-full py-[6px] px-5"
                              >
                                <CopyIcon />
                                <span className="text-[#1D1233] font-normal pt-[2px]">
                                  {item.sid}
                                </span>
                              </button>
                            </div>
                            <div className="flex flex-col w-full border-[#E8E5F6] border rounded-lg m-0 p-5 ">
                              <div className="flex flex-row items-center justify-start gap-4 mb-2">
                                <span className="text-[#2BCC58] text-xs font-semibold uppercase leading-[26px]">
                                  Assistant answer
                                </span>
                                {item.feedback != null ? (
                                  <>
                                    {item.feedback === "like" ? (
                                      <span className="border border-[#E8E5F6] bg-[#FAF9FF] text-black rounded-xl uppercase text-xs leading-[28px] h-[26px] flex flex-row items-center justify-center px-4 gap-2 font-semibold">
                                        <ThumbsUpIcon
                                          widht={16}
                                          height={14}
                                          fill="#1D1233"
                                        />
                                        <span className="inline-block pt-0.5">
                                          messaged liked
                                        </span>
                                      </span>
                                    ) : (
                                      <span className="border border-[#E8E5F6] bg-[#FAF9FF] text-black rounded-xl uppercase text-xs leading-[28px] h-[26px] flex flex-row items-center justify-center px-4 gap-2 font-semibold">
                                        <ThumbsDownIcon
                                          widht={16}
                                          height={14}
                                          fill="#1D1233"
                                        />
                                        <span className="inline-block pt-0.5">
                                          messaged disliked
                                        </span>
                                      </span>
                                    )}
                                  </>
                                ) : null}
                              </div>
                              <div className="flex flex-col">
                                <span className="text-[#1D1233] text-sm font-normal leading-[26px]">
                                  - {item.answer}
                                </span>
                                {item.feedback_message ? (
                                  <div className="flex flex-row items-center justify-start">
                                    <FeedbackMessageAccordion
                                      itemid={item.id}
                                      itemmsg={item.feedback_message}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                  <div className="w-full flex items-center justify-center my-6">
                    {loadingNext ? (
                      <Loader size="md" />
                    ) : (
                      hasNextPage && (
                        <button
                          onClick={getNextData}
                          className="bg-[#5E37FF] py-4 px-10 text-white rounded-full flex items-center justify-center"
                        >
                          Next Data
                        </button>
                      )
                    )}
                  </div>
                </Accordion>
              ) : (
                <div className="w-full flex flex-col items-center justify-center bg-white py-24">
                  <span className="text-[#1D1233] text-2xl font-semibold">
                    Empty conversations
                  </span>
                  <span className="text-base font-semibold text-[#1D1233] pt-2">
                    No conversations yet has been received
                  </span>
                </div>
              )}
            </div>
            <div className="mt-[18px]">
              <button
                onClick={() => doRefetch()}
                type="button"
                className={`${
                  messagesIsRefetching ? "opacity-50" : "opacity-100"
                } border-[#5E37FF] flex items-center gap-x-2.5 max-w-[200px] w-full justify-center border rounded-full py-3 px-5 font-semibold bg-[#FAF9FF]`}
              >
                <span className="pt-1 text-[#5E37FF]">Refresh</span>
                <RefreshIcon loading={messagesIsRefetching} />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center mt-32 justify-center w-full">
            <Loader />
          </div>
        )}
      </div>
    </>
  );
};

export default ChatsPage;
