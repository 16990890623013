import { Loader } from "@mantine/core";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
  useFunctionsCall,
  useFunctionsQuery,
} from "@react-query-firebase/functions";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import ModalAddAdmin from "../components/Modals/ModalAddAdmin";
import TableUsers from "../components/Views/_settings/TableUsers";
import { firestore, functions } from "../firebase";
import { collection, query, where } from "firebase/firestore";

const AdminPage = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [openAddAdminModal, setOpenAddAdminModal] = useState(false);

  // Get admins
  const { data: usersAdminsData, isLoading: usersAdminsLoading } =
    useFunctionsQuery(
      ["getProjectAdmins", projectId],
      functions,
      "project-onGetProjectAdmins",
      {
        projectId: projectId,
      },
      {},
      {
        refetchOnWindowFocus: false,
      }
    );

  const {
    data: invitationsData,
    isLoading: invitationsLoading,
    refetch: refetchInvitations,
  } = useFirestoreQuery(
    ["hashes", projectId],
    query(collection(firestore, "hashes"), where("project", "==", projectId)),
    {},
    {
      refetchOnWindowFocus: false,
    }
  );



  // Delete admin from project
  const onDeleteAdminFromProject = useFunctionsCall(
    functions,
    "project-onDeleteAdmin"
  );
  const deleteUser = (id) => {
    onDeleteAdminFromProject.mutate(
      {
        projectId: projectId,
        deleteId: id,
      },
      {
        onSuccess: (data) => {
          if (data.success) {
            queryClient.invalidateQueries({
              queryKey: "getProjectAdmins",
            });
          }
        },
      }
    );
  };

  return (
    <>
      <ModalAddAdmin
        opened={openAddAdminModal}
        setOpened={setOpenAddAdminModal}
        projectId={projectId}
      />

      {!usersAdminsLoading ? (
        <div className="mt-[50px] w-full px-6 pb-[56px]">
          <TableUsers
            headerBtnLabel={"Add user"}
            headerBtnEvent={() => setOpenAddAdminModal(true)}
            usersData={usersAdminsData?.data}
            deleteUser={deleteUser}
            isLoading={onDeleteAdminFromProject.isLoading}
          />
        </div>
      ) : (
        <div className="flex items-center mt-32 justify-center w-full">
          <Loader />
        </div>
      )}
    </>
  );
};

export default AdminPage;
