import React from "react";

const ChartIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.8759 8.74784H14.4789V2.25969C17.0714 2.95515 19.2529 4.76352 20.4102 7.22486C20.5819 7.59244 21.0179 7.74945 21.3855 7.57582C21.7512 7.40311 21.9091 6.96718 21.7373 6.60144C20.2485 3.4391 17.306 1.20496 13.8684 0.627724C13.6532 0.593552 13.4381 0.651737 13.2727 0.790274C13.1083 0.930659 13.0141 1.13477 13.0141 1.35089V9.48116C13.0141 9.88661 13.3411 10.2145 13.7465 10.2145H21.8759C22.2813 10.2145 22.6092 9.88661 22.6092 9.48116C22.6092 9.07663 22.2813 8.74784 21.8759 8.74784ZM18.9444 12.3572H10.8825V6.70673C10.8825 6.30312 10.5546 5.97433 10.1492 5.97433C9.74373 5.97433 9.41586 6.30312 9.41586 6.70673V13.0905C9.41586 13.496 9.74373 13.8229 10.1492 13.8229H18.1788C17.8084 17.9273 14.3487 21.1524 10.1492 21.1524C5.70399 21.1524 2.08725 17.5357 2.08725 13.0905C2.08725 8.6444 5.70399 5.02766 10.1492 5.02766C10.5546 5.02766 10.8825 4.69886 10.8825 4.29526C10.8825 3.88981 10.5546 3.56194 10.1492 3.56194C4.89586 3.56194 0.620605 7.83627 0.620605 13.0905C0.620605 18.3438 4.89586 22.6182 10.1492 22.6182C15.4034 22.6182 19.6768 18.3438 19.6768 13.0905C19.6768 12.6851 19.3499 12.3572 18.9444 12.3572Z"
        fill={fill ? fill : "#1D1233"}
        stroke={fill ? fill : "#1D1233"}
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default ChartIcon;
