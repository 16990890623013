import { Select } from "@mantine/core";
import ArrowDown from "../../assets/icons/ArrowDown";

const SelectSearch = ({ data, label, ...rest }) => {
  return (
    <Select
      clearable={true}
      classNames={{
        wrapper: "w-full",
        root: "flex flex-row items-center justify-start gap-4 flex-stretch [&>div]:w-full",
        input:
          "w-full h-12 px-6 rounded-full border-[#D9D6EA] focus:border-[#5E37FF]",
        label: "text-poppins text-[#1D1233] text-sm whitespace-nowrap",
        rightSection: "w-3 mr-6",
        dropdown: "bg-white text-black w-full border-[#D9D6EA] rounded-lg",
      }}
      label={label ? label : ""}
      placeholder=""
      searchable
      nothingFound="No options"
      data={data}
      rightSection={<ArrowDown />}
      {...rest}
    />
  );
};

export default SelectSearch;
