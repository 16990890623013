import { Loader } from "@mantine/core";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { collection, doc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../../../firebase";

const SourcesCompanySettings = ({ projectData }) => {
  const { projectId } = useParams();
  const [company_name, setCompanyName] = useState("");

  const mutation_projects = useFirestoreDocumentMutation(
    doc(collection(firestore, "projects"), projectId),
    { merge: true }
  );

  const handleSaveCompanyName = useCallback(() => {
    if (mutation_projects.isLoading) return;

    mutation_projects.mutate({
      company_name,
    });
  }, [company_name, mutation_projects]);

  const handleCompanyNameChange = (e) => {
    if (e.target.value.length <= 40) {
      setCompanyName(e.target.value);
    }
  };

  // Get Company Name
  useEffect(() => {
    if (projectData.data()) {
      let company_name = projectData.data()?.company_name;
      setCompanyName(company_name ?? "");
    }
  }, [projectData]);

  return (
    <div className="flex items-center w-full gap-x-5">
      <div className="flex flex-col gap-y-[32px] w-full">
        <span className="text-2xl font-semibold text-[#1D1233]">
          Company Name
        </span>
        <div className="bg-white rounded-lg px-6 py-6 flex items-center gap-x-4">
          <input
            placeholder="Company name"
            onChange={handleCompanyNameChange}
            value={company_name}
            spellCheck={false}
            className="outline-none flex-1 rounded-full border border-[#D9D6EA] px-6 py-[11px] text-[#1D1233] text-base font-normal"
          />
          <button
            type="button"
            onClick={handleSaveCompanyName}
            disabled={mutation_projects.isLoading}
            className={`${
              mutation_projects.isLoading ? "opacity-60" : "opacity-100"
            } bg-[#2BCC58] text-white flex items-center justify-center font-semibold text-base py-3 px-5 rounded-full w-[240px]`}
          >
            {!mutation_projects.isLoading ? (
              <span className="pt-1">Save company name</span>
            ) : (
              <Loader size="sm" color="gray" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SourcesCompanySettings;
