import React from "react";

const EyeIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "16"}
      viewBox="0 0 24 16"
      fill="none"
    >
      <g clipPath="url(#clip0_782_141611)">
        <path
          d="M15 8C15 9.65 13.65 11 12 11C10.35 11 9 9.65 9 8C9 6.35 10.35 5 12 5C13.65 5 15 6.35 15 8ZM24 7.55C24 7.55 19.75 16 12.02 16C4.84 16 0 7.55 0 7.55C0 7.55 4.45 0 12.02 0C19.59 0 24 7.55 24 7.55ZM17 8C17 5.24 14.76 3 12 3C9.24 3 7 5.24 7 8C7 10.76 9.24 13 12 13C14.76 13 17 10.76 17 8Z"
          fill={fill ? fill : "#CDCCD0"}
        />
      </g>
      <defs>
        <clipPath id="clip0_782_141611">
          <rect width="24" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
