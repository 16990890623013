import { Accordion, AspectRatio, Loader } from "@mantine/core";
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { firestore, functions } from "../firebase";
import { useQuery } from "react-query";
import PlusIcon from "../assets/icons/new/PlusIcon";
import { httpsCallable } from "firebase/functions";

const processChartFormat = (data) => {
  const chartData = data.map(({ time, count }) => {
    return {
      time: new Date(time),
      count: count,
    };
  });
  chartData.sort((a, b) => a.time - b.time);
  for (
    var i = 0,
      now = new Date(),
      d = new Date(new Date().setDate(now.getDate() - 29));
    d <= now;
    d.setDate(d.getDate() + 1), i++
  ) {
    if (i < chartData.length && chartData[i].time > d) {
      chartData.splice(i, 0, { time: new Date(d), count: 0 });
    }
  }
  return chartData.map(({ time, count }) => ({
    name: time.getUTCDate(),
    value: count,
  }));
};

const ChartsPage = () => {
  const { projectId } = useParams();
  const [activeTopic, setActiveTopic] = useState("");

  const [messagesSentChartData, setMessagesSentChartData] = useState([]);
  const [activeUsersChartData, setActiveUsersChartData] = useState([]);

  useEffect(() => {
    if (projectId) {
      const getActiveUsers = httpsCallable(functions, "metrics-getActiveUsers");
      const getMessagesSent = httpsCallable(
        functions,
        "metrics-getMessagesSent"
      );

      getActiveUsers(projectId).then((result) =>
        setActiveUsersChartData(processChartFormat(result.data))
      );
      getMessagesSent(projectId).then((result) =>
        setMessagesSentChartData(processChartFormat(result.data))
      );
    }
  }, [projectId]);

  const { data: projectData, isLoading: projectLoading } = useFirestoreDocument(
    ["projects", projectId],
    doc(firestore, "projects", projectId)
  );

  const { data: topicQuestionsData, isLoading: topicQuestionsLoading } =
    useQuery(
      ["topicQuestions", projectId, activeTopic],
      async () => {
        const getMessagesQuery = query(
          collection(firestore, "projects", projectId, "messages"),
          where("topic", "==", activeTopic)
        );
        const getMessagesData = (await getDocs(getMessagesQuery)).docs.map(
          (i) => i.data()
        );

        return getMessagesData;
      },
      {
        enabled: !!activeTopic.length,
      }
    );

  const getTopics = useMemo(() => {
    if (!projectLoading && projectData.data().computed_topics?.length) {
      const initialData = projectData.data().computed_topics;

      const updatedData = initialData
        .map((i) => ({
          count: i.count,
          topic: i.topic.split("_")[1],
        }))
        .sort((a, b) => b.count - a.count);

      return updatedData;
    } else {
      return [];
    }
  }, [projectData, projectLoading]);

  const getTopicsForQuestions = useMemo(() => {
    if (!projectLoading && projectData.data().computed_topics?.length) {
      const initialData = projectData.data().computed_topics;

      const updatedData = initialData
        .sort((a, b) => b.count - a.count)
        .map((i) => ({
          raw_topic: i.topic,
          topic: i.topic.split("_")[1],
        }));

      return updatedData;
    } else {
      return [];
    }
  }, [projectData, projectLoading]);

  useEffect(() => {
    if (!!getTopicsForQuestions.length) {
      setActiveTopic(getTopicsForQuestions[0].raw_topic);
    }
  }, [getTopicsForQuestions]);

  // GET RAW_TOPIC THAT HAS TOPIC EQUAL TO CLICKED TOPIC
  // AND SET TABS TO THAT TOPIC
  const handleBarClick = (data, index) => {
    const target = getTopicsForQuestions.find(
      (t) => t.topic === data.payload.topic
    );

    setActiveTopic(target.raw_topic);
  };

  return (
    <div className="mt-[50px] w-full px-6 pb-10">
      {!projectLoading ? (
        <div className="mt-[32px]">
          <div className="bg-white w-full flex flex-col rounded-lg pt-[34px] pb-[20px] pr-6 mt-5">
            <span className="font-semibold text-[#1D1233] text-base px-6">
              Active Users (30 days)
            </span>
            <AspectRatio ratio={10 / 2} className="w-full mt-6 relative">
              {activeUsersChartData.length === 0 && (
                <div className="absolute inset-0 text-[#1D1233] pb-6 font-semibold text-base">
                  No users this month
                </div>
              )}
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={activeUsersChartData}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 5" stroke="#E8E5F6" />
                  <XAxis dataKey="name" stroke="#C4C1CF" strokeWidth={1} />
                  <YAxis stroke="#C4C1CF" strokeWidth={1} />
                  <Tooltip
                    content={
                      <TooltipCustom
                        data={activeUsersChartData}
                        metric="value"
                        displayMetric="users"
                      />
                    }
                    labelStyle={{ background: "" }}
                  />
                  <Line type="monotone" stroke="#f43f5e" strokeWidth={3} />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#5E37FF"
                    activeDot={{ stroke: "#5E37FF", strokeWidth: 2, r: 5 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </AspectRatio>
          </div>

          <div className="bg-white w-full flex flex-col rounded-lg pt-[34px] pb-[20px] pr-6 mt-5">
            <span className="font-semibold text-[#1D1233] text-base px-6">
              Messages (30 days)
            </span>
            <AspectRatio ratio={10 / 2} className="w-full mt-6 relative">
              {messagesSentChartData.length === 0 && (
                <div className="absolute inset-0 text-[#1D1233] pb-6 font-semibold text-base">
                  No messages this month
                </div>
              )}
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={500}
                  height={300}
                  data={messagesSentChartData}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 5" stroke="#E8E5F6" />
                  <XAxis dataKey="name" stroke="#C4C1CF" strokeWidth={1} />
                  <YAxis stroke="#C4C1CF" strokeWidth={1} />
                  <Tooltip
                    content={
                      <TooltipCustom
                        data={messagesSentChartData}
                        metric="value"
                        displayMetric="messages"
                      />
                    }
                    labelStyle={{ background: "" }}
                  />
                  <Line type="monotone" stroke="#f43f5e" strokeWidth={3} />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#5E37FF"
                    activeDot={{ stroke: "#5E37FF", strokeWidth: 2, r: 5 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </AspectRatio>
          </div>

          <div className="flex items-stretch gap-x-[34px] bg-white mt-5 pt-[34px] pb-[20px] pr-6 rounded-lg">
            <div className="w-full flex flex-col">
              <span className="font-semibold text-[#1D1233] text-base px-6">
                Topics
              </span>
              <AspectRatio
                ratio={getTopics.length ? 10 / 7 : 10 / 2}
                className="w-full mt-6 relative"
              >
                {getTopics.length === 0 && (
                  <div className="absolute inset-0 text-[#1D1233] pb-6 font-semibold text-base">
                    No topics yet
                  </div>
                )}
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    margin={{
                      top: 5,
                      right: 10,
                      left: 30,
                      bottom: 5,
                    }}
                    style={{ position: "absolute" }}
                    layout="vertical"
                    width={730}
                    height={"100%"}
                    data={getTopics}
                  >
                    <CartesianGrid strokeDasharray="3 5" stroke="#E8E5F6" />
                    <XAxis type="number" stroke="#C4C1CF" strokeWidth={1} />
                    <YAxis
                      className="capitalize"
                      tickLine={false}
                      tickMargin={34}
                      width={getTopics.length ? 300 : 0}
                      type="category"
                      dataKey="topic"
                      stroke="#1D1233"
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                      strokeWidth={1}
                    />
                    <Tooltip
                      cursor={{
                        fill: getTopics.length ? "#ccc" : "transparent",
                      }}
                      content={<TooltipCustomBar data={getTopics} />}
                      labelStyle={{ background: "" }}
                    />
                    <Bar
                      dataKey="count"
                      radius={8}
                      barSize={30}
                      fill="#5E37FF"
                      onClick={handleBarClick}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </AspectRatio>
            </div>
            <div className="flex flex-col max-w-[306px] cs_lg:max-w-[426px] w-full">
              <span className="text-[#1D1233] font-semibold">Questions</span>
              <span className="text-[#777281] text-sm font-normal pt-3">
                Most common questions asked by topic.
              </span>
              <div className="flex flex-wrap gap-1 mt-6">
                {getTopicsForQuestions.map((item, index) => (
                  <button
                    onClick={() => {
                      setActiveTopic(item.raw_topic);
                    }}
                    key={index}
                    type="button"
                    className={`border outline-none cursor-pointer border-[#D9D6EA] ${
                      activeTopic === item.raw_topic
                        ? "bg-[#D9D6EA]"
                        : "bg-white"
                    } text-[#1D1233] max-w-[90px] text-[10px] font-semibold h-[32px] px-3 flex items-center justify-center rounded-full`}
                  >
                    <span className="truncate capitalize">{item.topic}</span>
                  </button>
                ))}
              </div>
              <div className="border no-scrollbar border-[#D9D6EA] rounded-lg p-5 mt-[26px] h-[367px] overflow-y-scroll">
                {!topicQuestionsLoading ? (
                  <Accordion
                    chevronPosition="right"
                    chevron={
                      <button
                        className="cursor-pointer outline-none rounded-full w-[32px] h-[32px] flex items-center justify-center"
                        type="button"
                      >
                        <PlusIcon />
                      </button>
                    }
                    styles={{
                      chevron: {
                        width: "32px",
                        height: "32px",
                      },
                      panel: {
                        marginLeft: "20px",
                        marginRight: "16px",
                      },
                      content: {
                        padding: 0,
                      },
                      label: {
                        paddingTop: "15.5px",
                        paddingBottom: "15.5px",
                      },
                      control: {
                        paddingLeft: 0,
                        ":hover": {
                          background: "transparent",
                        },
                      },
                    }}
                    className="w-full styled-scrollbar overflow-y-scroll max-h-[calc(100vh-296px)] h-full"
                  >
                    {!!topicQuestionsData?.length ? (
                      topicQuestionsData.map((item, index) => (
                        <Accordion.Item
                          key={index}
                          value={item.sid}
                          className="border-b border-[#D9D6EA]  w-full"
                        >
                          <Accordion.Control>
                            <div className="flex flex-col w-full flex-1">
                              <span className="text-[#1D1233] text-sm font-normal leading-[26px]">
                                {index + 1}. {item.question}
                              </span>
                            </div>
                          </Accordion.Control>
                          <Accordion.Panel className="bg-[#FAF9FF] rounded-lg">
                            <div className="flex flex-col gap-y-5 p-5 w-full">
                              <div className="flex flex-col w-full">
                                <span className="text-[#2BCC58] text-xs font-semibold uppercase leading-[26px]">
                                  Assistant answer
                                </span>
                                <span className="text-[#1D1233] text-sm font-normal leading-[26px]">
                                  {item.answer}
                                </span>
                              </div>
                              <div className="flex flex-col w-full"></div>
                            </div>
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))
                    ) : (
                      <div className="w-full flex items-center justify-center my-auto h-full">
                        <span className="text-[#1D1233] text-base text-center font-medium">
                          Empty questions topic
                        </span>
                      </div>
                    )}
                  </Accordion>
                ) : (
                  <div className="w-full flex items-center justify-center py-10">
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center mt-32 justify-center w-full">
          <Loader />
        </div>
      )}
    </div>
  );
};

const TooltipCustomBar = (props) => {
  return (
    <>
      <div className="bg-white p-3 rounded-md border-gray-200 border flex flex-col mt-6 relative">
        <div className="flex items-center">
          <span className="font-normal text-[#1D1233] text-sm">
            Topic:{" "}
            <span className="font-semibold capitalize">{props.label}</span>
          </span>
        </div>
        <div className="flex items-center">
          <span className="font-bold text-[#5E37FF] text-sm capitalize">
            Count: {props.data.find((i) => i.topic === props.label)?.count}
          </span>
        </div>
        <div className="bg-white border-t border-l border-gray-200 w-[12px] h-[12px] transform rotate-45 absolute -top-[11%] left-4"></div>
      </div>
    </>
  );
};

const TooltipCustom = (props) => {
  return (
    <div className="bg-white relative p-3 rounded-md border-gray-200 border flex flex-col mb-5 right-[30px]">
      <div className="flex items-center">
        <span className="font-bold text-[#5E37FF] text-sm capitalize">
          {props.displayMetric ?? props.metric}:{" "}
          {props.data.find((i) => i.name === props.label)?.[props.metric]}
        </span>
      </div>
      <div className="bg-white border-b border-r border-gray-200 w-[12px] h-[12px] transform rotate-45 absolute -bottom-[15%] left-4"></div>
    </div>
  );
};

export default ChartsPage;
