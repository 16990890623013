import { zodResolver } from "@hookform/resolvers/zod";
import { clsx } from "clsx";
import React, { useState } from "react";
import {
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSignInWithGoogle,
  useSignInWithMicrosoft,
  useUpdateProfile,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import GoogleIcon from "../assets/icons/GoogleIcon";
import MicrosoftIcon from "../assets/icons/MicrosoftIcon";
import EyeIcon from "../assets/icons/new/EyeIcon";
import LogoVertical from "../assets/illustrations/LogoVertical";
import { auth } from "../firebase";

// 1 uppercase, 1 lowercase, 1 special character, >=12 characters
const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){12,}$/;
// same, but with at least 1 number
const regex_with_number =
  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){12,}$/;

const schema = z
  .object({
    fullname: z.string().min(1, { message: "Required" }),
    email: z
      .string()
      .min(1, { message: "Required" })
      .email({ message: "Invalid email" }),
    password: z
      .string()
      .min(12, {
        message: "Passwords must be at least 12 characters in length",
      })
      .regex(regex, {
        message:
          "Passwords must contain at least one uppercase, one lowercase, and one non-alphanumeric character.",
      }),
    confirmpassword: z
      .string()
      .min(12, {
        message: "Passwords must be at least 12 characters in length",
      })
      .regex(regex, {
        message:
          "Passwords must contain at least one uppercase, one lowercase, and one non-alphanumeric character.",
      }),
  })
  .superRefine((data, ctx) => {
    if (data.password !== data.confirmpassword) {
      ctx.addIssue({
        code: "custom",
        message: "Passwords do not match",
        path: ["passwordMatch"],
      });
    }
  });

const SignUp = () => {
  const [signInWithGoogle, userGoogle, loadingGoogle] =
    useSignInWithGoogle(auth);
  const [signInWithMicrosoft, userMicrosoft, loadingMicrosoft] =
    useSignInWithMicrosoft(auth);

  const [
    createUserWithEmailAndPassword,
    userCreate,
    loadingCreate,
    errorCreate,
  ] = useCreateUserWithEmailAndPassword(auth);
  const [
    sendEmailVerification,
    sendingEmailVerification,
    errorSendEmailVerification,
  ] = useSendEmailVerification(auth);

  const [updateProfile, updating, errorUpdate] = useUpdateProfile(auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: "",
      email: "",
      password: "",
      confirmpassword: "",
    },
    resolver: zodResolver(schema),
  });

  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const handleLoginGoogle = () => {
    if (!userGoogle) {
      signInWithGoogle();
    }
  };

  const handleLoginMicrosoft = () => {
    if (!userMicrosoft) {
      signInWithMicrosoft();
    }
  };

  const onSubmitSignUp = (data) => {
    if (!userCreate) {
      createUserWithEmailAndPassword(data.email, data.password).then(
        async (created) => {
          if (created.user.uid) {
            await updateProfile({ displayName: data.fullname });
            await sendEmailVerification();
          }
        }
      );
    }
  };

  const errorsCodeCreate = {
    "auth/email-already-in-use": "Email already in use",
  };

  return (
    <div className="flex items-center gap-x-[60px] h-full w-full">
      <div className="flex-1 pl-[10%]">
        <div className="flex flex-col max-w-[460px] w-fulljustify-center items-center">
          <LogoVertical />
          <div className="mt-[60px] flex flex-col gap-y-3.5 text-[#1D1233] text-center">
            <h2 className="font-semibold text-[48px] leading-8">
              Get started!
            </h2>
            <span className="font-normal text-base">
              Create your account now
            </span>
          </div>
          <div className="mt-[30px] flex items-center justify-center gap-x-[26px]">
            <button
              onClick={handleLoginGoogle}
              className="rounded-full w-[64px] h-[64px] bg-[#1D1233] flex items-center justify-center outline-none"
            >
              <GoogleIcon width={20} height={20} />
            </button>
            <button
              onClick={handleLoginMicrosoft}
              className="rounded-full w-[64px] h-[64px] bg-[#1D1233] flex items-center justify-center outline-none"
            >
              <MicrosoftIcon width={20} height={20} fill="#ffffff" />
            </button>
          </div>
          <form
            onSubmit={handleSubmit(onSubmitSignUp)}
            className="w-full flex flex-col gap-y-5 mt-[56px]"
          >
            <div className="flex flex-col gap-y-4">
              <div
                className={clsx(
                  "w-full",
                  !!errors?.fullname?.message
                    ? "flex flex-col gap-y-1"
                    : "block"
                )}
              >
                <input
                  disabled={
                    loadingGoogle ||
                    loadingMicrosoft ||
                    loadingCreate ||
                    updating ||
                    sendingEmailVerification
                  }
                  {...register("fullname")}
                  type="text"
                  placeholder="Full name"
                  className={clsx(
                    `border disabled:opacity-50 rounded-full w-full text-base font-normal text-[#1D1D1D] py-[18px] px-5 outline-none`,
                    !!errors?.fullname?.message
                      ? "border-red-400"
                      : "border-[#C0B3C3]"
                  )}
                />
                {errors?.fullname?.message && (
                  <span className="text-red-400 font-semibold text-xs pl-3">
                    {errors?.fullname?.message}
                  </span>
                )}
              </div>

              <div
                className={clsx(
                  "w-full",
                  !!errors?.email?.message ? "flex flex-col gap-y-1" : "block"
                )}
              >
                <input
                  disabled={loadingGoogle || loadingMicrosoft || loadingCreate}
                  {...register("email")}
                  type="text"
                  placeholder="E-mail"
                  className={clsx(
                    `border disabled:opacity-50 rounded-full w-full text-base font-normal text-[#1D1D1D] py-[18px] px-5 outline-none`,
                    !!errors?.email?.message
                      ? "border-red-400"
                      : "border-[#C0B3C3]"
                  )}
                />
                {errors?.email?.message && (
                  <span className="text-red-400 font-semibold text-xs pl-3">
                    {errors?.email?.message}
                  </span>
                )}
              </div>

              <div
                className={clsx(
                  "w-full",
                  !!errors?.password?.message ||
                    !!errors?.passwordMatch?.message
                    ? "flex flex-col gap-y-1"
                    : "block"
                )}
              >
                <div
                  className={clsx(
                    `w-full flex items-center border rounded-full px-5`,
                    !!errors?.password?.message ||
                      !!errors?.passwordMatch?.message
                      ? "border-red-400"
                      : "border-[#C0BEC3]",
                    (loadingGoogle ||
                      loadingMicrosoft ||
                      loadingCreate ||
                      updating ||
                      sendingEmailVerification) &&
                      "pointer-events-none opacity-50"
                  )}
                >
                  <input
                    {...register("password")}
                    type={viewPassword ? "text" : "password"}
                    placeholder="Password"
                    className="text-base font-normal text-[#1D1D1D] outline-none flex-1 pr-2  bg-transparent py-[18px]"
                  />
                  <button
                    type="button"
                    onClick={() => setViewPassword((prev) => !prev)}
                  >
                    <EyeIcon fill={viewPassword ? "#1D1D1D" : "#CDCCD0"} />
                  </button>
                </div>
                {errors?.password?.message && (
                  <span className="text-red-400 font-semibold text-xs pl-3">
                    {errors?.password?.message}
                  </span>
                )}
              </div>

              <div
                className={clsx(
                  "w-full",
                  !!errors?.confirmpassword?.message ||
                    !!errors?.passwordMatch?.message
                    ? "flex flex-col gap-y-1"
                    : "block"
                )}
              >
                <div
                  className={clsx(
                    `w-full flex items-center border rounded-full px-5`,
                    !!errors?.confirmpassword?.message ||
                      !!errors?.passwordMatch?.message
                      ? "border-red-400"
                      : "border-[#C0BEC3]",
                    (loadingGoogle ||
                      loadingMicrosoft ||
                      loadingCreate ||
                      updating ||
                      sendingEmailVerification) &&
                      "pointer-events-none opacity-50"
                  )}
                >
                  <input
                    {...register("confirmpassword")}
                    type={viewConfirmPassword ? "text" : "password"}
                    placeholder="Confirm password"
                    className="text-base font-normal text-[#1D1D1D] outline-none flex-1 pr-2  bg-transparent py-[18px]"
                  />
                  <button
                    type="button"
                    onClick={() => setViewConfirmPassword((prev) => !prev)}
                  >
                    <EyeIcon
                      fill={viewConfirmPassword ? "#1D1D1D" : "#CDCCD0"}
                    />
                  </button>
                </div>
                {(errors?.confirmpassword?.message ||
                  errors?.passwordMatch?.message ||
                  !!errorCreate?.code ||
                  !!errorUpdate?.code ||
                  !!errorSendEmailVerification?.code) && (
                  <span className="text-red-400 font-semibold text-xs pl-3">
                    {errors?.confirmpassword?.message ||
                      errors?.passwordMatch?.message ||
                      errorsCodeCreate[errorCreate?.code] ||
                      "Something went wrong, try again later"}
                  </span>
                )}
              </div>
            </div>

            <button
              disabled={
                loadingGoogle ||
                loadingMicrosoft ||
                loadingCreate ||
                updating ||
                sendingEmailVerification
              }
              className="bg-[#5E37FF] disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
              type="submit"
            >
              Sign up
            </button>
            <div className="text-[#898690] text-sm font-normal text-center">
              Already have an account?{" "}
              <Link to="/" className="font-semibold text-[#1D1233]">
                Log in
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="flex-1 min-h-screen relative overflow-hidden h-full">
        <video
          src="/sphere_side_t.mp4"
          width="100%"
          height="100%"
          className="absolute top-1/2 w-full min-h-screen transform -translate-y-1/2 -right-[0%]"
          loop
          autoPlay
          playsInline
          muted
        ></video>
      </div>
    </div>
  );
};

export default SignUp;
