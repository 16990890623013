import React from "react";

const UserAvatarIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "14"}
      height={height ? height : "18"}
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M13.9978 14.718C13.9942 14.6478 13.985 14.5788 13.9717 14.5098C13.895 14.2769 13.6266 13.9416 12.7848 13.6549C12.669 13.6154 12.5539 13.575 12.4387 13.5332C12.1599 13.432 11.8871 13.3151 11.6116 13.2053C11.4927 13.1581 11.3745 13.1095 11.257 13.059C10.8604 12.8888 10.4573 12.6987 10.1016 12.452C9.79489 12.2389 9.45221 12.0055 9.26621 11.6725C9.11692 11.4053 9.16303 11.0595 9.16223 10.7614C9.16203 10.6906 9.15604 10.18 9.15784 10.084C9.70448 9.40694 10.1611 8.53003 10.4982 7.64052C10.7251 7.64112 11.0107 7.24157 11.159 6.70098C11.3175 6.12158 11.257 5.59999 11.0241 5.53597C11.0123 5.53277 11.0001 5.53157 10.988 5.53077C11.0043 5.3479 11.0135 5.16244 11.0135 4.97437C11.0135 2.22719 10.5176 0.0876312 7.0002 0C3.48284 0.0876312 2.98669 2.22719 2.98669 4.97437C2.98669 5.16244 2.99567 5.3481 3.01223 5.53077C3.00006 5.53157 2.98768 5.53277 2.97611 5.53597C2.7432 5.59999 2.68273 6.12158 2.84139 6.70098C2.98928 7.24157 3.27508 7.64112 3.5018 7.64052C3.83888 8.53003 4.29552 9.40694 4.84216 10.084C4.84396 10.1796 4.83797 10.6906 4.83777 10.7614C4.83717 11.0593 4.88347 11.4053 4.73399 11.6725C4.54798 12.0055 4.20551 12.2389 3.89856 12.452C3.54291 12.6987 3.13976 12.889 2.7432 13.059C2.62565 13.1095 2.5073 13.1581 2.38855 13.2053C2.11313 13.3151 1.84051 13.432 1.5615 13.5332C1.44634 13.575 1.33119 13.6154 1.21543 13.6549C0.37361 13.9418 0.105178 14.2769 0.0285396 14.5098C0.0151679 14.5788 0.00578779 14.6478 0.00239496 14.718C0.00119749 14.744 9.53042e-09 14.7697 9.53042e-09 14.7959C-0.000199569 16.5657 3.13417 18 7 18C10.8656 18 14 16.5657 14 14.7957C14 14.7697 13.9988 14.744 13.9978 14.718Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
};

export default UserAvatarIcon;
