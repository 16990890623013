import React from "react";

const KeyIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "20"}
      height={height ? height : "20"}
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_1864_1880)">
        <path
          d="M10.755 1.75494C8.40714 -0.592956 4.56987 -0.573204 2.19768 1.79899C-0.174511 4.17118 -0.194262 8.00845 2.15364 10.3563C2.15373 10.3564 2.15382 10.3565 2.15391 10.3566C3.76952 11.9688 6.16325 12.5107 8.33105 11.7789L9.98965 13.4375C10.034 13.4819 10.0895 13.5136 10.1503 13.5296C10.2112 13.5456 10.2754 13.5451 10.3364 13.5284L11.9799 13.0768L11.5283 14.7203C11.5115 14.7813 11.5111 14.8455 11.5271 14.9064C11.543 14.9672 11.5748 15.0226 11.6192 15.067L11.7982 15.2461C11.8426 15.2905 11.898 15.3222 11.9589 15.3382C12.0198 15.3541 12.084 15.3537 12.145 15.337L13.7884 14.8854L13.3369 16.5289C13.3201 16.5899 13.3197 16.6541 13.3357 16.7149C13.3516 16.7758 13.3834 16.8312 13.4278 16.8756L13.5721 17.0199C13.6164 17.0643 13.6719 17.096 13.7327 17.112C13.7936 17.128 13.8578 17.1275 13.9188 17.1108L15.5623 16.6592L15.1107 18.3027C15.0939 18.3637 15.0935 18.4279 15.1095 18.4888C15.1255 18.5496 15.1572 18.6051 15.2016 18.6494L16.1845 19.6323C16.2244 19.6722 16.2733 19.702 16.3271 19.7192C16.381 19.7364 16.4383 19.7406 16.4943 19.7314L19.0518 19.3096C19.1265 19.2973 19.1957 19.2617 19.2494 19.208L19.7515 18.7059C19.8138 18.6436 19.8514 18.561 19.8572 18.4735L20.0241 15.9509C20.0275 15.9002 20.02 15.8494 20.0022 15.802C19.9845 15.7546 19.9568 15.7116 19.9211 15.6758L12.1763 7.93104C12.9079 5.7639 12.3665 3.3709 10.7554 1.75527C10.7552 1.75516 10.7551 1.75506 10.755 1.75494ZM5.8247 3.47851C5.95127 3.60507 6.05142 3.75558 6.11942 3.92145C6.18741 4.08731 6.22194 4.26529 6.22101 4.44521C6.22008 4.62512 6.18373 4.80346 6.11402 4.97004C6.04431 5.13661 5.94261 5.28816 5.81473 5.41604C5.68686 5.54392 5.53531 5.64561 5.36873 5.71532C5.20216 5.78503 5.02382 5.82139 4.8439 5.82232C4.66398 5.82324 4.48601 5.78872 4.32014 5.72072C4.15427 5.65272 4.00376 5.55258 3.8772 5.42601C3.62161 5.1704 3.47908 4.82267 3.48096 4.45933C3.48284 4.09598 3.62898 3.74678 3.88724 3.48854C4.14548 3.23029 4.49468 3.08415 4.85802 3.08226C5.22137 3.08038 5.56909 3.22292 5.8247 3.47851Z"
          fill={fill ? fill : "#1D1233"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1864_1880">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default KeyIcon;
