import React from "react";

const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M8.19183 1.275C7.96917 0.88722 7.64949 0.565323 7.26481 0.341553C6.88013 0.117784 6.44396 0 6 0C5.55604 0 5.11987 0.117784 4.73519 0.341553C4.35051 0.565323 4.03083 0.88722 3.80817 1.275L0.327772 7.33019C0.113925 7.70153 0.000867147 8.12325 4.96841e-06 8.55279C-0.00085721 8.98234 0.110507 9.40451 0.322861 9.77673C0.535216 10.1489 0.841049 10.458 1.20951 10.6728C1.57796 10.8876 1.99601 11.0004 2.42146 11H9.57854C10.004 11.0004 10.422 10.8876 10.7905 10.6728C11.1589 10.458 11.4648 10.1489 11.6771 9.77673C11.8895 9.40451 12.0009 8.98234 12 8.55279C11.9991 8.12325 11.8861 7.70153 11.6722 7.33019L8.19183 1.275ZM9.57854 9.89906H2.42146C2.18763 9.89905 1.95795 9.83673 1.75562 9.7184C1.55328 9.60008 1.38546 9.42993 1.26911 9.22515C1.15276 9.02038 1.09201 8.78823 1.09298 8.55215C1.09396 8.31608 1.15662 8.08445 1.27466 7.88066L4.75142 1.82547C4.87822 1.60453 5.06032 1.42111 5.27946 1.29361C5.4986 1.16611 5.74708 1.09899 6 1.09899C6.25292 1.09899 6.5014 1.16611 6.72054 1.29361C6.93968 1.42111 7.12178 1.60453 7.24858 1.82547L10.7253 7.88066C10.8435 8.08458 10.9061 8.31638 10.907 8.55261C10.9079 8.78884 10.847 9.02112 10.7304 9.22595C10.6139 9.43078 10.4458 9.6009 10.2432 9.71909C10.0406 9.83729 9.8107 9.89937 9.57672 9.89906H9.57854Z"
        fill="#ED1C24"
      />
      <path
        d="M6.00007 4.39435C5.85546 4.39435 5.71678 4.45234 5.61453 4.55558C5.51228 4.65881 5.45483 4.79883 5.45483 4.94482V6.22925C5.45483 6.37525 5.51228 6.51526 5.61453 6.6185C5.71678 6.72173 5.85546 6.77973 6.00007 6.77973C6.14467 6.77973 6.28335 6.72173 6.3856 6.6185C6.48785 6.51526 6.5453 6.37525 6.5453 6.22925V4.94482C6.5453 4.79883 6.48785 4.65881 6.3856 4.55558C6.28335 4.45234 6.14467 4.39435 6.00007 4.39435Z"
        fill="#ED1C24"
      />
      <path
        d="M6.00007 3.10986C5.85546 3.10986 5.71678 3.16786 5.61453 3.27109C5.51228 3.37433 5.45483 3.51434 5.45483 3.66034V4.94477C5.45483 5.09076 5.51228 5.23078 5.61453 5.33401C5.71678 5.43724 5.85546 5.49524 6.00007 5.49524C6.14467 5.49524 6.28335 5.43724 6.3856 5.33401C6.48785 5.23078 6.5453 5.09076 6.5453 4.94477V3.66034C6.5453 3.51434 6.48785 3.37433 6.3856 3.27109C6.28335 3.16786 6.14467 3.10986 6.00007 3.10986Z"
        fill="#ED1C24"
      />
      <path
        d="M6.00007 8.61462C6.30119 8.61462 6.5453 8.36816 6.5453 8.06414C6.5453 7.76013 6.30119 7.51367 6.00007 7.51367C5.69894 7.51367 5.45483 7.76013 5.45483 8.06414C5.45483 8.36816 5.69894 8.61462 6.00007 8.61462Z"
        fill="#ED1C24"
      />
    </svg>
  );
};
export default WarningIcon;
