import React from "react";

const OpenChatIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_7_856)">
        <path
          d="M12 0C8.8174 0 5.76515 1.26428 3.51472 3.51472C1.26428 5.76515 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24C13.6247 24.0007 15.2322 23.6678 16.723 23.022L20.271 23.909C20.5124 23.9692 20.7602 23.9998 21.009 24C21.4642 23.9985 21.913 23.8934 22.3216 23.6927C22.7301 23.492 23.0877 23.201 23.3671 22.8417C23.6465 22.4824 23.8405 22.0642 23.9344 21.6188C24.0283 21.1734 24.0196 20.7125 23.909 20.271L23.022 16.723C23.6678 15.2322 24.0007 13.6247 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM21 16.873L21.97 20.756C22.0117 20.9234 22.0094 21.0988 21.9632 21.265C21.9171 21.4313 21.8287 21.5827 21.7067 21.7047C21.5847 21.8267 21.4333 21.9151 21.267 21.9612C21.1008 22.0074 20.9254 22.0097 20.758 21.968L16.873 21C16.7935 20.9802 16.7119 20.9701 16.63 20.97C16.4842 20.9699 16.3402 21.0016 16.208 21.063C14.8912 21.6807 13.4545 22.0006 12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51808 17.6541 2.7612 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92893 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9999 13.4546 21.6793 14.8914 21.061 16.208C20.9651 16.4161 20.9435 16.6509 21 16.873Z"
          fill={fill ? fill : "#1D1233"}
        />
        <path
          d="M8 9C7.40666 9 6.82664 9.17595 6.33329 9.50559C5.83994 9.83524 5.45543 10.3038 5.22836 10.8519C5.0013 11.4001 4.94189 12.0033 5.05765 12.5853C5.1734 13.1672 5.45912 13.7018 5.87868 14.1213C6.29824 14.5409 6.83279 14.8266 7.41473 14.9424C7.99667 15.0581 8.59987 14.9987 9.14805 14.7716C9.69623 14.5446 10.1648 14.1601 10.4944 13.6667C10.8241 13.1734 11 12.5933 11 12C11 11.2044 10.6839 10.4413 10.1213 9.87868C9.55871 9.31607 8.79565 9 8 9ZM8 13C7.80222 13 7.60888 12.9414 7.44443 12.8315C7.27998 12.7216 7.15181 12.5654 7.07612 12.3827C7.00043 12.2 6.98063 11.9989 7.01922 11.8049C7.0578 11.6109 7.15304 11.4327 7.29289 11.2929C7.43275 11.153 7.61093 11.0578 7.80491 11.0192C7.99889 10.9806 8.19996 11.0004 8.38268 11.0761C8.56541 11.1518 8.72159 11.28 8.83147 11.4444C8.94135 11.6089 9 11.8022 9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957 12.8946 8.26522 13 8 13Z"
          fill={fill ? fill : "#1D1233"}
        />
        <path
          d="M16 9C15.4067 9 14.8266 9.17595 14.3333 9.50559C13.8399 9.83524 13.4554 10.3038 13.2284 10.8519C13.0013 11.4001 12.9419 12.0033 13.0576 12.5853C13.1734 13.1672 13.4591 13.7018 13.8787 14.1213C14.2982 14.5409 14.8328 14.8266 15.4147 14.9424C15.9967 15.0581 16.5999 14.9987 17.1481 14.7716C17.6962 14.5446 18.1648 14.1601 18.4944 13.6667C18.8241 13.1734 19 12.5933 19 12C19 11.2044 18.6839 10.4413 18.1213 9.87868C17.5587 9.31607 16.7957 9 16 9ZM16 13C15.8022 13 15.6089 12.9414 15.4444 12.8315C15.28 12.7216 15.1518 12.5654 15.0761 12.3827C15.0004 12.2 14.9806 11.9989 15.0192 11.8049C15.0578 11.6109 15.153 11.4327 15.2929 11.2929C15.4327 11.153 15.6109 11.0578 15.8049 11.0192C15.9989 10.9806 16.2 11.0004 16.3827 11.0761C16.5654 11.1518 16.7216 11.28 16.8315 11.4444C16.9414 11.6089 17 11.8022 17 12C17 12.2652 16.8946 12.5196 16.7071 12.7071C16.5196 12.8946 16.2652 13 16 13Z"
          fill={fill ? fill : "#1D1233"}
        />
      </g>
      <defs>
        <clipPath id="clip0_7_856">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OpenChatIcon;
