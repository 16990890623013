import React from "react";

const CopyIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "12"}
      height={height ? height : "12"}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.32131 10.707C1.50885 10.8945 1.76321 10.9999 2.02843 10.9999H7.57157C7.83679 10.9999 8.09114 10.8945 8.27868 10.707L8.30711 10.6786C8.49464 10.491 8.6 10.2367 8.6 9.9715V4.42833C8.6 4.16311 8.49464 3.90876 8.30711 3.72122L8.27868 3.6928C8.27868 3.69279 8.27869 3.6928 8.27868 3.6928C8.09115 3.50527 7.83678 3.3999 7.57157 3.3999H2.02843C1.76321 3.3999 1.50886 3.50526 1.32132 3.69279L1.29289 3.72122C1.10536 3.90876 1 4.16311 1 4.42833V9.9715C1 10.2367 1.10535 10.491 1.29289 10.6786L1.32131 10.707ZM9.0142 3.01412L8.98579 2.98569C8.61071 2.61062 8.10201 2.3999 7.57157 2.3999H2.02843C1.49799 2.3999 0.98929 2.61062 0.61421 2.98569L0.58579 3.01412C0.21071 3.38919 0 3.8979 0 4.42833V9.9715C0 10.5019 0.21071 11.0106 0.58579 11.3857L0.61421 11.4141C0.98929 11.7892 1.49799 11.9999 2.02843 11.9999H7.57157C8.10201 11.9999 8.61071 11.7892 8.98579 11.4141L9.0142 11.3857C9.3893 11.0106 9.6 10.5019 9.6 9.9715V4.42833C9.6 3.8979 9.3893 3.38919 9.0142 3.01412Z"
        fill={fill ? fill : "#5E37FF"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.6004 8.6H9.972C10.2372 8.6 10.4915 8.49464 10.6791 8.30711L10.7075 8.27868C10.895 8.09114 11.0004 7.83679 11.0004 7.57157V2.02843C11.0004 1.76321 10.895 1.50886 10.7075 1.32132L10.6791 1.29289C10.4915 1.10535 10.2372 1 9.972 1H4.42882C4.1636 1 3.90925 1.10536 3.72171 1.29289L3.69329 1.32132C3.50575 1.50886 3.40039 1.76321 3.40039 2.02843V2.39999H2.40039V2.02843C2.40039 1.49799 2.61111 0.98929 2.98618 0.61421L3.01461 0.58579C3.38968 0.21071 3.89839 0 4.42882 0H9.972C10.5024 0 11.0111 0.21071 11.3862 0.58579L11.4146 0.61421C11.7897 0.98929 12.0004 1.49799 12.0004 2.02843V7.57157C12.0004 8.10201 11.7897 8.61071 11.4146 8.98579L11.3862 9.0142C11.0111 9.3893 10.5024 9.6 9.972 9.6H9.6004V8.6Z"
        fill={fill ? fill : "#5E37FF"}
      />
    </svg>
  );
};

export default CopyIcon;
