export const Testimonials = [
  {
    title: `“Creating a World-Class Support Experience with AI”`,
    author: "Ana-Maria Constantin",
    subtitle: "CEO & Co-Founder of Cascade Health",
  },
  {
    title: `“Creating a World-Class Support Experience with AI”`,
    author: "Pulak Goyal",
    subtitle: "CTO & Co-Founder of Cascade Health",
  },
  {
    title: `“Creating a World-Class Support Experience with AI“`,
    author: "Ana-Maria Constantin",
    subtitle: "CEO & Co-Founder of Cascade Health",
  },
];
