import React, { useEffect, useState } from "react";
import {
  useAuthState,
  useSendEmailVerification,
} from "react-firebase-hooks/auth";
import { auth, firestore, functions } from "../../firebase";
import { useQuery } from "react-query";
import { doc, getDoc } from "firebase/firestore";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { Loader, Modal } from "@mantine/core";
import CloseIcon from "../../assets/icons/CloseIcon";

const CreateCollectionModal = ({ close, opened, projectsRefetch }) => {
  const [userAuth] = useAuthState(auth);
  const [error, setError] = useState("");
  const [preventEmailSpam, setPreventEmailSpam] = useState(false);

  const [sendEmailVerification] = useSendEmailVerification(auth);

  const { data: userData } = useQuery(
    ["userAuthed", userAuth?.uid],
    async () => {
      const user_ref = doc(firestore, "users", userAuth?.uid);
      const user_data = await getDoc(user_ref);

      return user_data.data();
    },
    {
      enabled: !!userAuth,
    }
  );

  const onCreateProject = useFunctionsCall(
    functions,
    "project-onCreateCollection"
  );
  const handleCreate = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setError("");

    const projectName = e.target[0].value;

    if (!e.target[0].value.length) {
      return setError("Invalid project name");
    }

    if (onCreateProject.isLoading) return;

    onCreateProject.mutate({
      projectName,
    });
  };

  const onCloseModal = () => {
    close();
    setError("");
  };

  const handleSendEmailVerification = async () => {
    if (!!preventEmailSpam) return;

    setPreventEmailSpam(true);

    await sendEmailVerification();
  };

  useEffect(() => {
    if (!!preventEmailSpam) {
      setTimeout(() => {
        setPreventEmailSpam(false);
      }, 1000 * 20);
    }
  }, [preventEmailSpam]);

  useEffect(() => {
    if (!onCreateProject.isLoading && onCreateProject.data) {
      if (onCreateProject.data.success === true) {
        close();
        projectsRefetch();
      } else {
        setError(onCreateProject.data.message);
      }
    }
  }, [onCreateProject.isLoading, close, onCreateProject.data, projectsRefetch]);

  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      styles={{
        title: { fontWeight: 600, fontSize: "20px" },
      }}
      onClose={onCloseModal}
      centered
    >
      {userAuth.emailVerified ? (
        <>
          <div className="grid grid-cols-3 w-full">
            <span className="text-xl font-semibold text-right text-slate-800 col-span-2">
              Create project
            </span>
            <div className="flex items-center justify-end">
              <button onClick={close} className="p-1 cursor-pointer">
                <CloseIcon width={18} height={18} fill="black" />
              </button>
            </div>
          </div>
          {!!userData?.credits ? (
            <form
              onSubmit={(e) => handleCreate(e)}
              className="mt-6 flex flex-col "
            >
              <span className="font-medium">Name of the project</span>
              <input maxLength={25} className="outline-none mt-1 border-gray-300 py-1.5 px-2 border rounded-md" />
              {!!error.length && (
                <p className="text-sm text-red-400 mt-2">{error}</p>
              )}
              <button className="bg-blue-500 rounded-lg mt-5 text-white py-2 flex items-center justify-center">
                {onCreateProject.isLoading ? (
                  <Loader color="gray" size={16} />
                ) : (
                  "Create"
                )}
              </button>
            </form>
          ) : (
            <div className="mt-6 font-semibold text-center">
              Not enough credits to create a project
            </div>
          )}
        </>
      ) : (
        <>
          <div className="grid grid-cols-3 w-full">
            <span className="text-xl font-semibold text-right pr-2 col-span-2 text-slate-800">
              Verify Email
            </span>
            <div className="flex items-center justify-end">
              <button onClick={close} className="p-1 cursor-pointer">
                <CloseIcon width={18} height={18} fill="black" />
              </button>
            </div>
          </div>
          <div className="mt-6 text-center">
            <span>
              Check your inbox and verify the mail by cliking on the link, if
              you didn't receive any email click the button bellow to resend the
              email
            </span>
            <button
              disabled={preventEmailSpam}
              onClick={handleSendEmailVerification}
              className="bg-[#5E37FF] w-full mt-5 disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
            >
              Resend email
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateCollectionModal;
