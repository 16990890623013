import React from "react";

const ArrowRight = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "18"}
      height={height ? height : "16"}
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.49951V6.99951H17V8.49951H0Z"
        fill={fill ? fill : "#A5A0AD"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14.9404L16.941 6.99536L18 8.05496L10.059 16L9 14.9404Z"
        fill={fill ? fill : "#A5A0AD"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.05929L10.059 0L18 7.94538L16.941 9.00467L9 1.05929Z"
        fill={fill ? fill : "#A5A0AD"}
      />
    </svg>
  );
};

export default ArrowRight;
