import React from "react";

const RefreshIcon = ({ width, height, fill, loading }) => {
  return (
    <svg
      className={loading ? "animate-spin" : "animate-none"}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "18"}
      height={height ? height : "18"}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.17568 15.8274C4.64698 15.8274 2.23514 11.9475 2.23514 9.14513C2.23514 6.34277 4.64698 2.4629 9.17568 2.4629C10.9724 2.49098 12.6916 3.17202 13.9885 4.36943L11.0258 4.32557L10.9997 5.99613L16.8081 6.07548L16.7105 0.5L14.9754 0.527147L15.0144 2.99957C13.4183 1.59476 11.3382 0.809146 9.17568 0.794436C3.98762 0.794436 0.5 5.11282 0.5 9.14722C0.5 13.1816 3.98546 17.5 9.17568 17.5C13.6155 17.5 16.55 14.4304 17.5 11.3858L15.8364 10.9076C15.073 13.3549 12.7219 15.8274 9.17568 15.8274Z"
        fill={fill ? fill : "#5E37FF"}
      />
    </svg>
  );
};

export default RefreshIcon;
