import { useFunctionsCall } from "@react-query-firebase/functions";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { auth, functions } from "../firebase";
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth";
import LogoVertical from "../assets/illustrations/LogoVertical";
import GoogleIcon from "../assets/icons/GoogleIcon";
import { Testimonials } from "../utils/testimonials";
import CascadeHealthIcon from "../assets/icons/CascadeHealthIcon";
import { Loader } from "@mantine/core";

const SlackBot = () => {
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const handleLogin = () => {
    signInWithGoogle();
  };

  const [userAuth, userLoading] = useAuthState(auth);
  const { projectId } = useParams();
  const linkProject = useFunctionsCall(functions, "project-processSlackCode");

  const processLinkProject = useCallback(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");
    linkProject.mutate({
      projectId: projectId,
      code,
    });
  }, [linkProject, projectId]);

  useEffect(() => {
    if (!userLoading && userAuth) {
      processLinkProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading, userAuth]);

  let testimonial = useMemo(() => {
    return Testimonials[Math.floor(Math.random() * 3)];
  }, []);

  return (
    !userLoading && (
      <div className="flex min-h-screen h-full w-full bg-[#F6F6FB] pt-[150px] flex-col justify-between items-center">
        <div className="max-w-[500px] w-full flex flex-col items-center justify-center">
          <LogoVertical />
          <h2 className="font-semibold text-[48px] leading-[32px] text-center mt-[60px] mb-[14px] text-[#1D1233]">
            {!userAuth
              ? "Welcome back!"
              : linkProject.isLoading
              ? "Processing"
              : linkProject.isSuccess
              ? "Success"
              : "Something wrong"}
          </h2>
          <span className="font-medium text-base leading-[24px] text-center mb-[30px] text-[#1D1233]">
            {!userAuth ? (
              "Before to integrate chatbot into slack, please sign in bellow"
            ) : linkProject.isLoading ? (
              <Loader size="md" color="violet" className="mt-5" />
            ) : linkProject.isSuccess ? (
              "Check slack to begin with chatbot"
            ) : (
              "There was an issue, try again later"
            )}
          </span>
          {!userAuth ? (
            <button
              onClick={handleLogin}
              className="bg-[#1D1233] mt-[16px] py-2.5 gap-x-3 font-semibold px-6 text-white rounded-[32px] cursor-poiner outline-none flex items-center justify-center"
            >
              <GoogleIcon fill="#ffffff" width="25px" height="25px" />
              Continue with Google
            </button>
          ) : !linkProject.isLoading ? (
            linkProject.isSuccess ? (
              <Link
                to="/"
                className="bg-[#1D1233] mt-[16px] py-2.5 font-semibold px-6 text-white rounded-[32px] cursor-poiner outline-none"
              >
                Go to dashboard
              </Link>
            ) : null
          ) : null}
        </div>

        <div className="bg-[#1D1233] h-full w-full">
          <div className="pl-[80px] pr-[20px] py-[150px]">
            <span className="font-semibold text-[28px] leading-[40px] text-white">
              {testimonial.title}
            </span>
            <div className="flex items-center gap-x-5 mt-[26px]">
              <CascadeHealthIcon />
              <div className="flex flex-col">
                <span className="font-semibold text-base text-white leading-[24px]">
                  {testimonial.author}
                </span>
                <span className="font-medium text-sm leading-6 text-white">
                  {testimonial.subtitle}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SlackBot;
