import { Modal } from "@mantine/core";
import React from "react";
import PlusIconBordered from "../../assets/icons/new/PlusIconBordered";

//todo: check if domain is valid
const ModalWhitelistDomain = ({ opened, setOpened, addDomain }) => {
  const [domain, setDomain] = React.useState("");
  const [isValidDomain, setIsValidDomain] = React.useState(true);

  const isValidDomainFormat = (inputDomain) => {
    const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainPattern.test(inputDomain);
  };

  const handleAddDomain = () => {
    if (isValidDomainFormat(domain)) {
      setIsValidDomain(true);
      addDomain(domain);
    } else {
      setIsValidDomain(false);
    }
  };

  const handleCloseModal = () => {
    setOpened(false);
    setDomain("");
    setIsValidDomain(true);
  };

  return (
    <Modal
      closeOnClickOutside={false}
      centered
      withCloseButton={false}
      opened={opened}
      size={560}
      radius="md"
    >
      <div className="w-full flex justify-end">
        <button
          onClick={handleCloseModal}
          className="text-[#777281] text-[14px] text-right p-1 bg-transparent outline-none font-normal"
        >
          Close
        </button>
      </div>
      <div className="w-full flex-col flex items-center justify-center mt-6 px-[44px] pb-[44px]">
        <span className="text-[30px] font-semibold text-center w-full text-[#1D1233]">
          Add Domain
        </span>
        <div className="mt-10 w-full">
          <input
            placeholder="eg.com"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            className={`border text-[#1D1233] text-base font-normal w-full outline-none border-[#C0BEC3] rounded-full bg-transparent py-4 px-5 ${
              !isValidDomain ? "border-red-500" : ""
            }`}
          />
          {!isValidDomain && (
            <p className="text-red-500 text-sm mt-2">Invalid domain format</p>
          )}
          <button
            onClick={handleAddDomain}
            className="mt-5 bg-[#5E37FF] text-white flex items-center justify-center gap-x-3 py-3 w-full rounded-full"
          >
            <span className="pt-1">Add Domain</span>
            <PlusIconBordered />
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWhitelistDomain;
