import { Image, Modal } from "@mantine/core";
import { clsx } from "clsx";
import React, { useCallback, useEffect } from "react";
import UserAvatarIcon from "../../assets/icons/new/UserAvatarIcon";

//todo: check if email and name valid
const ModalEditAdmin = ({
  opened,
  handleCloseEditModal,
  editAdminData,
  onSubmit,
}) => {
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [isNameNotEmpty, setIsNameNotEmpty] = React.useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleEditEmail = (email) => {
    if (validateEmail(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  useEffect(() => {
    setEmail(editAdminData?.email || "");
    setName(editAdminData?.name || "");
  }, [editAdminData]);
  const beforeSubmit = useCallback(() => {
    if (name.trim() === "") {
      setIsNameNotEmpty(false);
      return;
    } else {
      setIsNameNotEmpty(true);
    }
    setEmail(email.trim());
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    } else {
      setIsValidEmail(true);
    }
    onSubmit({
      id: editAdminData.id,
      email,
      name,
    });
  }, [email, name, editAdminData.id, validateEmail, onSubmit]);

  return (
    <Modal
      closeOnClickOutside={false}
      centered
      withCloseButton={false}
      opened={opened}
      size={560}
      radius="md"
      transitionProps={{
        duration: 100,
      }}
    >
      <div className="w-full flex justify-end">
        <button
          onClick={handleCloseEditModal}
          className="text-[#777281] text-[14px] text-right p-1 bg-transparent outline-none font-normal"
        >
          Close
        </button>
      </div>
      <div className="w-full flex-col flex items-center justify-center mt-6 px-[44px] pb-[44px]">
        <span className="text-[30px] font-semibold text-center w-full text-[#1D1233]">
          Edit email
        </span>
        <div className="mt-10 w-full flex flex-col justify-center items-center">
          <div
            className={clsx(
              "w-[120px] h-[120px] rounded-full",
              !editAdminData.photoUrl &&
                "bg-[#473BF066] flex items-center justify-center"
            )}
          >
            {editAdminData.photoUrl ? (
              <Image
                src={editAdminData.photoUrl}
                className="w-full h-full rounded-full"
                radius={999}
              />
            ) : (
              <UserAvatarIcon width={60} height={60} />
            )}
          </div>
          <span className="pt-5 text-[#1D1233] font-semibold truncate max-w-[250px] text-center text-2xl">
            {editAdminData.name}
          </span>
          <span className="text-[#1D1D1D] text-base font-normal pt-3">
            {editAdminData.email}
          </span>
          <div className="mt-10 flex flex-col gap-y-2.5 w-full">
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`border text-[#1D1233] text-base font-normal w-full outline-none border-[#C0BEC3] rounded-full bg-transparent py-4 px-5 ${
                !isNameNotEmpty ? "border-red-500" : ""
              }`}
            />
            {!isNameNotEmpty && (
              <p className="text-red-500 text-sm mt-2">Name cannot be empty</p>
            )}
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className={`border text-[#1D1233] text-base font-normal w-full outline-none border-[#C0BEC3] rounded-full bg-transparent py-4 px-5 ${
                !isValidEmail ? "border-red-500" : ""
              }`}
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm mt-2">Invalid email format</p>
            )}
            <button
              onClick={beforeSubmit}
              className="mt-2.5 bg-[#5E37FF] text-white flex items-center justify-center gap-x-3 py-4 w-full rounded-full"
            >
              <span className="pt-1">Update user</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditAdmin;
