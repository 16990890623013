import React from "react";

const ChatIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_7_845)">
        <path
          d="M2.3009 12.3131C1.36788 10.7412 1.04085 8.8827 1.38123 7.08671C1.72161 5.29071 2.70598 3.68077 4.14946 2.55924C5.59294 1.43772 7.39622 0.881776 9.22062 0.995827C11.045 1.10988 12.765 1.88608 14.0576 3.17864C15.3502 4.47121 16.1264 6.19121 16.2404 8.01562C16.3545 9.84002 15.7985 11.6433 14.677 13.0868C13.5555 14.5303 11.9455 15.5146 10.1495 15.855C8.35354 16.1954 6.49506 15.8684 4.92314 14.9353L2.33212 15.6741C2.22537 15.7042 2.11254 15.7052 2.00525 15.6772C1.89796 15.6491 1.80008 15.593 1.72166 15.5146C1.64325 15.4362 1.58713 15.3383 1.55909 15.231C1.53105 15.1237 1.53208 15.0109 1.5621 14.9041L2.3009 12.3131Z"
          stroke={fill ? fill : "#1D1233"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33643 15.9759C8.70317 17.0215 9.29759 17.9726 10.0767 18.7604C10.8559 19.5483 11.8003 20.1533 12.8418 20.5316C13.8833 20.91 14.9958 21.0523 16.0989 20.9482C17.2021 20.8442 18.2684 20.4964 19.2208 19.93L21.8118 20.6688C21.9186 20.6988 22.0314 20.6999 22.1387 20.6718C22.246 20.6438 22.3438 20.5877 22.4223 20.5093C22.5007 20.4308 22.5568 20.333 22.5848 20.2257C22.6129 20.1184 22.6118 20.0056 22.5818 19.8988L21.843 17.3078C22.5026 16.2006 22.865 14.9419 22.8951 13.6535C22.9253 12.3651 22.6221 11.0908 22.015 9.95403C21.4079 8.81726 20.5174 7.85658 19.4299 7.1651C18.3424 6.47361 17.0947 6.07476 15.8077 6.0072"
          stroke={fill ? fill : "#1D1233"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_845">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatIcon;
