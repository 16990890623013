import React, { useEffect, useState } from "react";
import SourcesCompanySettings from "../components/Views/_settings/SourcesCompanySettings";
import Integrations from "../components/Views/_settings/Integrations";
import WelcomeMessageSettings from "../components/Views/_settings/WelcomeMessageSettings";
import { useParams } from "react-router-dom";
import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { firestore } from "../firebase";
import { CheckIcon, Loader, Notification } from "@mantine/core";

const CompanyDetailsPage = () => {
  const { projectId } = useParams();

  // Get project data
  const {
    data: projectData,
    isLoading: projectLoading,
    refetch: projectRefetch,
  } = useFirestoreDocument(
    ["projects", projectId],
    doc(firestore, "projects", projectId)
  );

  const [succeed, setSucceed] = useState(false);
  useEffect(() => {
    if (succeed) {
      const t = setTimeout(() => {
        setSucceed(false);
      }, 1500);

      return () => {
        clearTimeout(t);
      };
    }
  }, [succeed]);

  return !projectLoading ? (
    <>
      <Notification
        styles={{
          root: {
            position: "fixed",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 100,
            opacity: succeed ? 1 : 0,
            transition: "opacity 300ms ease",
          },
        }}
        icon={<CheckIcon width={12} height={12} />}
        withBorder
        withCloseButton={false}
      >
        Success!
      </Notification>

      <div className="mt-[50px] w-full px-6 pb-[56px]">
        <div className="mt-[58px] w-full">
          <SourcesCompanySettings
            projectData={projectData}
            projectRefetch={projectRefetch}
          />
        </div>

        <div className="mt-[58px] w-full">
          <Integrations />
        </div>

        <div className="mt-[64px] w-full">
          <WelcomeMessageSettings
            projectData={projectData}
            projectRefetch={projectRefetch}
            setSucceed={setSucceed}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="flex items-center mt-32 justify-center w-full">
      <Loader />
    </div>
  );
};

export default CompanyDetailsPage;
