import React from "react";

const PlusIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "24"}
      height={height ? height : "25"}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12 8.5V16.5"
        stroke={fill ? fill : "#1D1233"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12.5H16"
        stroke={fill ? fill : "#1D1233"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
