import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import EditIcon from "../../../assets/icons/new/EditIcon";
import PlusIconBordered from "../../../assets/icons/new/PlusIconBordered";
import TrashIcon from "../../../assets/icons/new/TrashIcon";

const TableDomains = ({
  domainsData,
  headerBtnLabel,
  headerBtnEvent,
  deleteDomain,
}) => {
  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("domain", {
        cell: (info) => info.getValue(),
        header: () => <span>Allowed Domains</span>,
        enableSorting: false,
      }),
      {
        header: () => (
          <button
            onClick={headerBtnEvent}
            className="flex items-center justify-center gap-x-3 py-3 w-full bg-[#5E37FF] rounded-full text-white"
          >
            <span className="pt-1">{headerBtnLabel}</span>
            <PlusIconBordered width={24} height={24} />
          </button>
        ),
        cell: (info) => {
          return (
            <div className="flex items-center justify-center gap-x-3">
              {/*<button*/}
              {/*  onClick={() => {}}*/}
              {/*  className="bg-[#5E37FF] rounded-full w-[32px] h-[32px] flex items-center justify-center"*/}
              {/*>*/}
              {/*  <EditIcon />*/}
              {/*</button>*/}
              <button
                onClick={() => deleteDomain(info.row.original.domain)}
                className="bg-[#F77171] rounded-full w-[32px] h-[32px] flex items-center justify-center"
              >
                <TrashIcon />
              </button>
            </div>
          );
        },
        accessorKey: "eventtabledomains",
      },
    ],
    [columnHelper, headerBtnLabel, headerBtnEvent]
  );

  const table = useReactTable({
    data: domainsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <div className="w-full overflow-auto no-scrollbar h-full max-h-[580px]">
        <table className="w-full bg-white rounded-lg h-full relative">
          <thead className="shadow-sm sticky top-0 bg-white rounded-tl-lg rounded-tr-lg">
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className={
                          "py-[27.75px] w-fit first-of-type:text-left pl-[24px] last-of-type:pr-[24px] [&_div]:ml-auto [&_div]:first-of-type:mr-auto [&_div]:first-of-type:ml-[initial]"
                        }
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className:
                                "select-none max-w-[241px] relative text-left font-semibold text-[#1D1233]",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 90)
              .map((row) => {
                return (
                  <tr
                    className="border-b border-[#FAF9FF] hover:bg-gray-100 cursor-pointer"
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td
                          className={
                            "text-left py-[27.5px] pl-[24px] last-of-type:flex last-of-type:justify-end pr-[24px]"
                          }
                          key={cell.id}
                        >
                          <span className="font-normal text-[#1D1233] text-base pt-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDomains;
