import { Link } from "react-router-dom";
import PersonIcon from "../assets/icons/new/PersonIcon";
import FilesControl from "../assets/icons/new/FilesControl";
import FilesCompany from "../assets/icons/new/FilesCompany";

const SettingsPage = () => {
  return (
    <div className="mt-[50px] w-full px-[130px] items-center justify-center flex">
      <div className="flex items-stretch justify-center flex-col gap-y-2.5 max-w-[924px] w-full">
        <Link
          to="admins"
          className="border border-[#E8E5F6] rounded-lg p-6 bg-white flex items-center gap-x-6  w-full"
        >
          <div className="w-full max-w-[88px] aspect-square rounded-full flex items-center justify-center bg-[#F6F3FFB2]">
            <PersonIcon />
          </div>
          <div className="flex flex-col">
            <span className="text-[#1D1233] text-[28px] font-semibold">
              Admins
            </span>
            <span className="text-[#1D1233] text-base font-normal">
              The dedicated team that ensure exceptional user experiences
            </span>
          </div>
        </Link>

        <Link
          to="access-control"
          className="border border-[#E8E5F6] rounded-lg p-6 bg-white flex items-center gap-x-6  w-full"
        >
          <div className="w-full max-w-[88px] aspect-square rounded-full flex items-center justify-center bg-[#F6F3FFB2]">
            <FilesControl />
          </div>
          <div className="flex flex-col">
            <span className="text-[#1D1233] text-[28px] font-semibold">
              Access control
            </span>
            <span className="text-[#1D1233] text-base font-normal">
              Whitelist and manage access to safeguard your data
            </span>
          </div>
        </Link>

        <Link
          to="company-details"
          className="border border-[#E8E5F6] rounded-lg p-6 bg-white flex items-center gap-x-6  w-full"
        >
          <div className="w-full max-w-[88px] aspect-square rounded-full flex items-center justify-center bg-[#F6F3FFB2]">
            <FilesCompany />
          </div>
          <div className="flex flex-col">
            <span className="text-[#1D1233] text-[28px] font-semibold">
              Company details
            </span>
            <span className="text-[#1D1233] text-base font-normal">
              Effortlessly manage your company information with ease
            </span>
          </div>
        </Link>
      </div>
    </div>
  );

  // const { projectId } = useParams();

  // // Get project data
  // const {
  //   data: projectData,
  //   isLoading: projectLoading,
  //   refetch: projectRefetch,
  // } = useFirestoreDocument(
  //   ["projects", projectId],
  //   doc(firestore, "projects", projectId)
  // );

  // // Get admins
  // const { data: usersAdminsData, isLoading: usersAdminsLoading } =
  //   useFunctionsQuery(
  //     ["getProjectAdmins", projectId],
  //     functions,
  //     "project-onGetProjectAdmins",
  //     {
  //       projectId: projectId,
  //     }
  //   );

  // // Get invitations
  // const {
  //   data: invitationsData,
  //   isLoading: invitationsLoading,
  //   refetch: refetchInvitations,
  // } = useFirestoreQuery(
  //   ["hashes", projectId],
  //   query(collection(firestore, "hashes"), where("project", "==", projectId))
  // );

  // // Generate invitation
  // const onGenerateTokenInvitation = useFunctionsCall(
  //   functions,
  //   "project-onGenerateTokenInvitation"
  // );
  // const handleGenerateToken = () => {
  //   if (
  //     onGenerateTokenInvitation.isLoading &&
  //     !onGenerateTokenInvitation.isSuccess
  //   )
  //     return;

  //   onGenerateTokenInvitation.mutate({
  //     projectId: projectId,
  //   });
  // };

  // // On generate refetch
  // useEffect(() => {
  //   if (
  //     !onGenerateTokenInvitation.isLoading &&
  //     onGenerateTokenInvitation.isSuccess
  //   ) {
  //     refetchInvitations();
  //     onGenerateTokenInvitation.reset();
  //   }
  // }, [onGenerateTokenInvitation, refetchInvitations]);

  // //Refetch project
  // useEffect(() => {
  //   projectRefetch();
  // }, [projectId, projectRefetch]);

  // return (
  //   <>
  //     {!usersAdminsLoading && !invitationsLoading && !projectLoading ? (
  //       <div className="mt-[50px] w-full px-6 pb-[56px]">
  //         <span className="text-2xl font-semibold text-[#1D1233]">Admins</span>
  //         <TableUsers usersData={usersAdminsData?.data} />

  //         <div className="mt-[60px] w-full">
  //           <span className="text-2xl font-semibold text-[#1D1233]">
  //             Invitations
  //           </span>
  //           <Invitations
  //             invitationsData={invitationsData}
  //             refetchInvitations={refetchInvitations}
  //             handleGenerateToken={handleGenerateToken}
  //             onGenerateTokenInvitation={onGenerateTokenInvitation}
  //           />
  //         </div>

  //         <div className="mt-[58px] w-full">
  //           <SourcesCompanySettings
  //             projectData={projectData}
  //             projectRefetch={projectRefetch}
  //           />
  //         </div>

  //         <div className="mt-[58px] w-full">
  //           <Integrations />
  //         </div>

  //         <div className="mt-[64px] w-full">
  //           <WelcomeMessageSettings
  //             projectData={projectData}
  //             projectRefetch={projectRefetch}
  //           />
  //         </div>
  //       </div>
  //     ) : (
  //       <div className="flex items-center mt-32 justify-center w-full">
  //         <Loader />
  //       </div>
  //     )}
  //   </>
  // );
};

export default SettingsPage;
