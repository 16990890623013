import React from "react";

const TrashIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "16"}
      height={height ? height : "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_277_402)">
        <path
          d="M8.6665 0H7.33317C6.80274 0 6.29403 0.210714 5.91896 0.585786C5.54388 0.960859 5.33317 1.46957 5.33317 2V2.66667H1.33317C1.15636 2.66667 0.98679 2.7369 0.861766 2.86193C0.736742 2.98695 0.666504 3.15652 0.666504 3.33333C0.666504 3.51014 0.736742 3.67971 0.861766 3.80474C0.98679 3.92976 1.15636 4 1.33317 4H1.99984V13.3333C1.99984 14.0406 2.28079 14.7189 2.78089 15.219C3.28098 15.719 3.95926 16 4.6665 16H11.3332C12.0404 16 12.7187 15.719 13.2188 15.219C13.7189 14.7189 13.9998 14.0406 13.9998 13.3333V4H14.6665C14.8433 4 15.0129 3.92976 15.1379 3.80474C15.2629 3.67971 15.3332 3.51014 15.3332 3.33333C15.3332 3.15652 15.2629 2.98695 15.1379 2.86193C15.0129 2.7369 14.8433 2.66667 14.6665 2.66667H10.6665V2C10.6665 1.46957 10.4558 0.960859 10.0807 0.585786C9.70565 0.210714 9.19694 0 8.6665 0ZM6.6665 2C6.6665 1.82319 6.73674 1.65362 6.86177 1.5286C6.98679 1.40357 7.15636 1.33333 7.33317 1.33333H8.6665C8.84332 1.33333 9.01288 1.40357 9.13791 1.5286C9.26293 1.65362 9.33317 1.82319 9.33317 2V2.66667H6.6665V2ZM12.6665 13.3333C12.6665 13.687 12.526 14.0261 12.276 14.2761C12.0259 14.5262 11.6868 14.6667 11.3332 14.6667H4.6665C4.31288 14.6667 3.97374 14.5262 3.72369 14.2761C3.47365 14.0261 3.33317 13.687 3.33317 13.3333V4H12.6665V13.3333Z"
          fill={fill ? fill : "white"}
        />
        <path
          d="M8.00016 6C7.82335 6 7.65378 6.07024 7.52876 6.19526C7.40373 6.32029 7.3335 6.48986 7.3335 6.66667V12C7.3335 12.1768 7.40373 12.3464 7.52876 12.4714C7.65378 12.5964 7.82335 12.6667 8.00016 12.6667C8.17697 12.6667 8.34654 12.5964 8.47157 12.4714C8.59659 12.3464 8.66683 12.1768 8.66683 12V6.66667C8.66683 6.48986 8.59659 6.32029 8.47157 6.19526C8.34654 6.07024 8.17697 6 8.00016 6Z"
          fill={fill ? fill : "white"}
        />
        <path
          d="M10 12C10 12.1768 10.0702 12.3464 10.1953 12.4714C10.3203 12.5964 10.4899 12.6667 10.6667 12.6667C10.8435 12.6667 11.013 12.5964 11.1381 12.4714C11.2631 12.3464 11.3333 12.1768 11.3333 12V6.66667C11.3333 6.48986 11.2631 6.32029 11.1381 6.19526C11.013 6.07024 10.8435 6 10.6667 6C10.4899 6 10.3203 6.07024 10.1953 6.19526C10.0702 6.32029 10 6.48986 10 6.66667V12Z"
          fill={fill ? fill : "white"}
        />
        <path
          d="M5.33317 6C5.15636 6 4.98679 6.07024 4.86177 6.19526C4.73674 6.32029 4.6665 6.48986 4.6665 6.66667V12C4.6665 12.1768 4.73674 12.3464 4.86177 12.4714C4.98679 12.5964 5.15636 12.6667 5.33317 12.6667C5.50998 12.6667 5.67955 12.5964 5.80457 12.4714C5.9296 12.3464 5.99984 12.1768 5.99984 12V6.66667C5.99984 6.48986 5.9296 6.32029 5.80457 6.19526C5.67955 6.07024 5.50998 6 5.33317 6Z"
          fill={fill ? fill : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_277_402">
          <rect width="16" height="16" fill={fill ? fill : "white"} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashIcon;
