import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { applyActionCode, verifyPasswordResetCode } from "firebase/auth";
import VerificationEmail from "./VerificationEmail";

const AuthAction = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const urlQueries = React.useMemo(() => new URLSearchParams(search), [search]);

  const mode = urlQueries.get("mode");
  const oobCode = urlQueries.get("oobCode");

  const [loading, setLoading] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  const [emailMessage, setEmailMessage] = useState("");

  const getEmail = async () => {
    const email = await verifyPasswordResetCode(auth, oobCode);

    return setEmailMessage(email);
  };

  const handleVerifyEmail = (actionCode) => {
    applyActionCode(auth, actionCode)
      .then((resp) => {
        window.location.href = "/";
      })
      .catch((error) => {
        navigate("/verify?reload=true", { replace: true });
      });
  };

  useEffect(() => {
    switch (mode) {
      case "resetPassword":
        setLoading(false);
        setShowResetPassword(true);
        break;
      case "verifyEmail":
        setLoading(false);
        setShowVerifyEmail(true);
        break;
      default:
    }

    getEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <></>;
  }

  if (showVerifyEmail) {
    return (
      <VerificationEmail
        email={emailMessage}
        onVerifyEmail={() => handleVerifyEmail(oobCode)}
      />
    );
  }

  if (showResetPassword) {
    return <></>;
  }
};

export default AuthAction;
