import React from "react";

const EditIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "17"}
      height={height ? height : "16"}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M15.1218 0.887904C14.8411 0.607076 14.5078 0.384304 14.141 0.232314C13.7742 0.0803237 13.3811 0.00209375 12.984 0.00209375C12.587 0.00209375 12.1938 0.0803237 11.827 0.232314C11.4602 0.384304 11.1269 0.607076 10.8462 0.887904L10.6729 1.05786L10.2897 0.667958C9.86053 0.240198 9.27932 0 8.6734 0C8.06748 0 7.48627 0.240198 7.05713 0.667958L4.2045 3.52392C4.10954 3.61676 4.04449 3.73585 4.01768 3.86591C3.99086 3.99597 4.00351 4.13107 4.054 4.25389C4.10449 4.37672 4.19052 4.48166 4.30105 4.55526C4.41159 4.62886 4.54159 4.66776 4.67438 4.66698C4.76233 4.66705 4.84942 4.64971 4.93064 4.61596C5.01186 4.58222 5.08559 4.53273 5.1476 4.47036L8.0069 1.61773C8.18657 1.44006 8.42905 1.34042 8.68173 1.34042C8.93441 1.34042 9.17689 1.44006 9.35656 1.61773L9.7298 2.00096L6.204 5.52677L0.87198 10.8588C0.769629 10.956 0.700783 11.0832 0.675362 11.222L0.00885838 15.2211C-0.00835581 15.3256 -0.000405721 15.4327 0.0320492 15.5335C0.0645042 15.6344 0.120529 15.726 0.195479 15.8009C0.258271 15.8641 0.333089 15.914 0.415513 15.9478C0.497936 15.9816 0.586293 15.9985 0.675362 15.9975C0.711945 16.0008 0.748751 16.0008 0.785334 15.9975L4.78435 15.331C4.92198 15.3084 5.04903 15.2431 5.1476 15.1444L15.1218 5.16352C15.6865 4.59534 16.0035 3.8268 16.0035 3.02571C16.0035 2.22463 15.6865 1.45608 15.1218 0.887904ZM4.34113 14.0413L1.47517 14.5212L1.96505 11.6653L6.67389 6.94309L9.0633 9.3325L4.34113 14.0413ZM14.1787 4.22042L10.0064 8.3894L7.61699 5.99998L11.7893 1.83101C12.1107 1.52284 12.5387 1.35079 12.984 1.35079C13.4293 1.35079 13.8573 1.52284 14.1787 1.83101C14.4939 2.14872 14.6708 2.57815 14.6708 3.02571C14.6708 3.47328 14.4939 3.9027 14.1787 4.22042Z"
        fill={fill ? fill : "white"}
      />
    </svg>
  );
};

export default EditIcon;
