import React from "react";

const ArrowDown = ({ width, height, fill, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "12"}
      height={height ? height : "13"}
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        d="M6.00288 8.04662L3.18141 4.66062C3.06788 4.52547 2.90543 4.44081 2.72962 4.42519C2.55382 4.40956 2.37898 4.46424 2.2434 4.57725C2.10783 4.69026 2.02256 4.85239 2.00627 5.02814C1.98998 5.20389 2.044 5.37893 2.1565 5.51493L5.49042 9.51562C5.55309 9.59063 5.63147 9.65098 5.72002 9.69238C5.80857 9.73379 5.90513 9.75525 6.00288 9.75525C6.10063 9.75525 6.19719 9.73379 6.28574 9.69238C6.37429 9.65098 6.45266 9.59063 6.51534 9.51562L9.84925 5.51493C9.96228 5.37899 10.0167 5.20374 10.0006 5.02767C9.98456 4.85161 9.89924 4.68913 9.76343 4.57593C9.62762 4.46273 9.45244 4.40807 9.27635 4.42395C9.10027 4.43983 8.93769 4.52495 8.82432 4.66062L6.00288 8.04662Z"
        fill={fill ? fill : "#777281"}
      />
    </svg>
  );
};

export default ArrowDown;
