import React from "react";

const SettingsArrow = ({ fill, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      className={className}
    >
      <path
        d="M11 5.41913C11.0004 5.49508 10.9851 5.57032 10.9549 5.64021C10.9248 5.71011 10.8804 5.77319 10.8246 5.82558C10.5889 6.05784 10.2157 6.05784 9.99962 5.82558L5.5 1.39333L1.00179 5.82697C0.766071 6.05922 0.392857 6.05922 0.176786 5.82697C-0.0589286 5.59471 -0.0589286 5.22697 0.176786 5.01406L5.0875 0.175354C5.14067 0.120366 5.20469 0.0766702 5.27563 0.0469523C5.34657 0.0172344 5.42292 0.00212247 5.5 0.00254304C5.57735 0.000232763 5.65429 0.0144864 5.72551 0.0443216C5.79672 0.0741568 5.86052 0.118863 5.9125 0.175354L10.8232 5.01406C10.879 5.06646 10.9234 5.12954 10.9535 5.19943C10.9837 5.26933 10.999 5.34456 10.9986 5.42051L11 5.41913Z"
        fill={fill ? fill : "#5E37FF"}
      />
    </svg>
  );
};

export default SettingsArrow;
