import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useMemo } from "react";
import PlusIconBordered from "../../../assets/icons/new/PlusIconBordered";
import { Loader } from "@mantine/core";
import { clsx } from "clsx";
import EditIcon from "../../../assets/icons/new/EditIcon";
import TrashIcon from "../../../assets/icons/new/TrashIcon";

const TableUsers = ({
  usersData,
  handleOpenEditModal,
  disableSpacingTop,
  headerBtnLabel,
  headerBtnEvent,
  deleteUser,
  isLoading,
}) => {
  const columnHelper = createColumnHelper();

  const onDeleteHandle = useCallback(
    (id) => {
      if (!isLoading) {
        deleteUser(id);
      }
    },
    [deleteUser, isLoading]
  );

  const onEditHandle = useCallback(
    (name, email, photoUrl, id) => {
      if (!isLoading && handleOpenEditModal) {
        handleOpenEditModal(name, email, photoUrl, id);
      }
    },
    [handleOpenEditModal, isLoading]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: () => <span>Name</span>,
        enableSorting: false,
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: () => <span>Email</span>,
        enableSorting: false,
      }),
      {
        header: () => (
          <button
            onClick={headerBtnEvent}
            className="flex items-center justify-center gap-x-3 py-3 w-full bg-[#5E37FF] rounded-full text-white"
          >
            <span className="pt-1">{headerBtnLabel}</span>
            <PlusIconBordered width={24} height={24} />
          </button>
        ),
        cell: (info) => {
          const { email, name, photoUrl, id } = info.row.original;
          return (
            <div className="flex items-center justify-center gap-x-3">
              {handleOpenEditModal && <button
                  onClick={() => onEditHandle(name, email, photoUrl, id)}
                  className="bg-[#5E37FF] rounded-full w-[32px] h-[32px] flex items-center justify-center"
              >
                  <EditIcon />
              </button>}

              <button
                onClick={() => onDeleteHandle(id)}
                className="bg-[#F77171] rounded-full w-[32px] h-[32px] flex items-center justify-center"
              >
                <TrashIcon />
              </button>
            </div>
          );
        },
        accessorKey: "eventtableuser",
      },
    ],
    [columnHelper, headerBtnLabel, headerBtnEvent, onDeleteHandle, onEditHandle]
  );

  const table = useReactTable({
    data: usersData ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={clsx(disableSpacingTop ? "mt-[0px]" : "mt-[32px]")}>
      <div className="w-full overflow-auto no-scrollbar h-full max-h-[580px]">
        <table className="w-full bg-white rounded-lg h-full relative">
          <thead className="shadow-sm sticky top-0 bg-white rounded-tl-lg rounded-tr-lg z-10">
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        className={clsx(
                          header.id === "eventtableuser" &&
                            "max-w-[241px] pr-[24px]",
                          "py-[27.75px] first-of-type:border-r-[#FAF9FF] w-fit first-of-type:border-r last-of-type:rounded-tr-lg first-of-type:rounded-tl-lg first-of-type:text-left pl-[24px] [&_div]:mr-auto [&_div]:ml-auto [&_div]:first-of-type:mr-auto [&_div]:first-of-type:ml-[initial]"
                        )}
                        key={header.id}
                        colSpan={header.colSpan}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className:
                                "select-none relative text-left font-semibold text-[#1D1233]",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody className="relative">
            {isLoading && (
              <div className="absolute flex items-center justify-center top-0 left-0 w-full h-full bg-white/80 z-10">
                <Loader size="md" />
              </div>
            )}
            {table
              .getRowModel()
              .rows.slice(0, 90)
              .map((row) => {
                return (
                  <tr
                    className="border-b border-[#FAF9FF] hover:bg-gray-100 cursor-pointer"
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td
                          className={
                            "text-left py-[27.5px] last-of-type:pr-[24px] last-of-type:flex last-of-type:justify-end first-of-type:w-full first-of-type:max-w-[300px] first-of-type:border-r-[#FAF9FF] first-of-type:border-r first-of-type:flex first-of-type:items-center first-of-type:gap-x-2 first-of-type:text-left first-of-type:font-medium first-of-type:text-[#000000] text-gray-400 pl-[24px]"
                          }
                          key={cell.id}
                        >
                          {index === 0 && (
                            <div className="rounded-full">
                              {cell.row.original.photoUrl ? (
                                <img
                                  loading="lazy"
                                  referrerPolicy="no-referrer"
                                  alt="profile-avatar"
                                  className="rounded-[inherit] w-[32px] h-[32px]"
                                  src={cell.row.original.photoUrl}
                                />
                              ) : (
                                <div className="rounded-full w-[32px] h-[32px] bg-gradient-to-r from-purple-500 to-pink-500"></div>
                              )}
                            </div>
                          )}
                          <span className="font-normal truncate text-[#1D1233] text-base pt-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableUsers;
