import { zodResolver } from "@hookform/resolvers/zod";
import { clsx } from "clsx";
import {
  OAuthProvider,
  signInWithPopup,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import {
  useSignInWithGoogle,
  useSignInWithMicrosoft,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import GoogleIcon from "../assets/icons/GoogleIcon";
import MicrosoftIcon from "../assets/icons/MicrosoftIcon";
import KeyIcon from "../assets/icons/new/KeyIcon";
import WarningIcon from "../assets/icons/new/WarningIcon";
import LogoVertical from "../assets/illustrations/LogoVertical";
import { auth, functions } from "../firebase";
const SignIn = () => {
  const [sendingMagicLink, setSendingMagicLink] = useState(false);

  const [signInWithGoogle, userGoogle, loadingGoogle] =
    useSignInWithGoogle(auth);
  const [signInWithMicrosoft, userMicrosoft, loadingMicrosoft] =
    useSignInWithMicrosoft(auth);

  const sendSignInLinkToEmail = httpsCallable(
    functions,
    "user-sendSignInLinkToEmail"
  );

  const getEmailOIDC = httpsCallable(functions, "user-getEmailOIDC");

  const [hasBeenSentMagicLink, setHasBeenSentMagicLink] = useState(false);
  const [continueWithSamlSSO, setContinueWithSamlSSO] = useState(false);

  useEffect(() => {
    if (
      !window?.signInWithEmailLink &&
      isSignInWithEmailLink(auth, window.location.href)
    ) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      // The client SDK will parse the code from the link for you.
      window.signInWithEmailLink = true;
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  const {
    register: registerSamlSso,
    handleSubmit: handleSubmitSamlSso,
    formState: formStateSamlSSo,
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .min(1, { message: "Required" })
          .email({ message: "Invalid email" }),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .min(1, { message: "Required" })
          .email({ message: "Please enter a valid email address." }),
      })
    ),
  });

  const handleLoginGoogle = () => {
    if (!userGoogle) {
      signInWithGoogle(["email", "profile"]);
    }
  };

  const handleLoginMicrosoft = () => {
    if (!userMicrosoft) {
      signInWithMicrosoft();
    }
  };

  const onContinueWithSamlSso = async (data) => {
    const providerMail = data.email.split("@")[1].toLowerCase();

    const { data: oidcData } = await getEmailOIDC({ email: data.email });

    if (!oidcData.success) {
      alert("No OIDC found for the email provider");
    } else {
      const oidc_id = oidcData.oidc;
      const oktaProvider = new OAuthProvider(oidc_id);

      signInWithPopup(auth, oktaProvider).then((result) => {
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
      });
    }
  };

  const actionCodeSettings = {
    url: `${window.location.protocol}//${window.location.host}/signin`,
    handleCodeInApp: true,
  };

  const [emailForResend, setEmailForResend] = useState("");
  const [emailResendError, setEmailResendError] = useState("");

  const onSubmitSignIn = async (data) => {
    setSendingMagicLink(true);
    setEmailResendError("");

    try {
      await sendSignInLinkToEmail({
        email: data.email,
        actionCodeSettings,
      })
        .then((a) => {
          setSendingMagicLink(false);
          localStorage.setItem("emailForSignIn", data.email);
          setEmailForResend(data.email);
          setHasBeenSentMagicLink(true);
        })
        .finally(() => {
          setSendingMagicLink(false);
        });
    } catch (err) {
      if (
        err?.code === "functions/resource-exhausted" &&
        !!err?.message?.length
      ) {
        setEmailResendError(err?.message);
      }
    }
  };

  const handleResendEmail = () => {
    onSubmitSignIn({
      email: emailForResend,
    });
  };

  const handleContinueSamlSSO = () => {
    if (!continueWithSamlSSO) return setContinueWithSamlSSO(true);
  };

  return (
    <div className="flex items-center gap-x-[60px] h-full w-full min-h-screen justify-between">
      <div className="flex-1">
        <div className="pl-[9.4%] cs_lg:pl-[13%]">
          <div className="flex flex-col max-w-[460px] w-full justify-center items-center">
            <LogoVertical />

            <div className="mt-[56px] flex flex-col gap-y-6 items-center justify-center text-[#1D1233] text-center">
              <h2
                className={twMerge(
                  hasBeenSentMagicLink
                    ? "max-w-[400px] w-full leading-[58px]"
                    : "leading-8",
                  "font-semibold font-poppins text-center text-cs_clamp_signin_header"
                )}
              >
                Welcome back!
              </h2>
            </div>

            {hasBeenSentMagicLink ? (
              <>
                <div className="leading-[22px] text-base font-normal !font-poppins text-[#898690] pt-6">
                  Haven&apos;t received our email?{" "}
                  <button
                    onClick={handleResendEmail}
                    className="text-[#1D1233] font-base font-semibold leading-[22px]"
                  >
                    Click here to resend it.
                  </button>
                </div>
                {!!emailResendError && (
                  <div className="flex items-center gap-x-2 pt-2">
                    <WarningIcon />

                    <span className="text-[#ED1C24] font-normal text-sm leading-5">
                      {emailResendError}
                    </span>
                  </div>
                )}

                <div className="flex flex-row items-center justify-center">
                  <button
                    className="bg-[#5E37FF] mt-10 w-auto disabled:opacity-50 px-8 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
                    type={"button"}
                    onClick={() => setHasBeenSentMagicLink(false)}
                  >
                    Back
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="mt-10 flex w-full flex-col items-center justify-center gap-y-4">
                  {!continueWithSamlSSO && (
                    <button
                      onClick={handleContinueSamlSSO}
                      className="flex items-center space-x-4 border border-[#C0B3C3] disabled:opacity-50 rounded-full w-full py-[15px] px-5 outline-none"
                    >
                      <KeyIcon />
                      <div className="bg-[#D9D6EA] h-[28px] w-[1px]"></div>
                      <span className="text-[#898690] font-normal text-base font-poppins">
                        Sign In with Okta
                      </span>
                    </button>
                  )}

                  {!continueWithSamlSSO && (
                    <>
                      <button
                        onClick={handleLoginGoogle}
                        className="flex items-center space-x-4 border border-[#C0B3C3] disabled:opacity-50 rounded-full w-full py-[15px] px-5 outline-none"
                      >
                        <GoogleIcon
                          width={"20px"}
                          height={"20px"}
                          fill="#1D1233"
                        />
                        <div className="bg-[#D9D6EA] h-[28px] w-[1px]"></div>
                        <span className="text-[#898690] font-normal text-base font-poppins">
                          Sign In with Google
                        </span>
                      </button>

                      <button
                        onClick={handleLoginMicrosoft}
                        className="flex items-center space-x-4 border border-[#C0B3C3] disabled:opacity-50 rounded-full w-full py-[15px] px-5 outline-none"
                      >
                        <MicrosoftIcon
                          width={"20px"}
                          height={"20px"}
                          fill="#1D1233"
                        />
                        <div className="bg-[#D9D6EA] h-[28px] w-[1px]"></div>
                        <span className="text-[#898690] font-normal text-base font-poppins">
                          Sign In with Microsoft
                        </span>
                      </button>
                    </>
                  )}
                </div>

                <div className="flex items-center justify-center mt-[24px] mb-[24px] gap-x-4 w-full">
                  {!continueWithSamlSSO && (<div className="bg-[#D9D6EA] h-[1px] w-full flex-1"></div>)}
                  <div className="text-[#898690] text-sm text-center font-normal font-poppins">
                    {!continueWithSamlSSO
                      ? "or use email"
                      : "Enter email to continue with sso"}
                  </div>
                  {!continueWithSamlSSO && (<div className="bg-[#D9D6EA] h-[1px] w-full flex-1"></div>)}
                </div>

                {!continueWithSamlSSO ? (
                  <form
                    onSubmit={handleSubmit(onSubmitSignIn)}
                    className="w-full flex flex-col gap-y-5"
                  >
                    <div className="flex flex-col gap-y-4">
                      <div
                        className={clsx(
                          "w-full",
                          !!errors?.email?.message
                            ? "flex flex-col gap-y-1"
                            : "block"
                        )}
                      >
                        <input
                          disabled={
                            loadingGoogle ||
                            loadingMicrosoft ||
                            sendingMagicLink
                          }
                          {...register("email")}
                          type="text"
                          placeholder="E-mail"
                          className={clsx(
                            `border disabled:opacity-50 rounded-full w-full text-base font-normal py-[18px] px-5 outline-none`,
                            !!errors?.email?.message
                              ? "border-[#ED1C24] text-[#ED1C24]"
                              : "border-[#C0B3C3] text-[#1D1D1D]"
                          )}
                        />
                        {errors?.email?.message && (
                          <div className="flex items-center gap-x-2">
                            <WarningIcon />

                            <span className="text-[#ED1C24] font-normal text-sm leading-5">
                              {errors?.email?.message ||
                                "Something went wrong, try again later"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      disabled={
                        loadingGoogle || loadingMicrosoft || sendingMagicLink
                      }
                      className="bg-[#5E37FF] !font-poppins disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
                      type="submit"
                    >
                      Sign In With Email
                    </button>
                  </form>
                ) : (
                  <form
                    onSubmit={handleSubmitSamlSso(onContinueWithSamlSso)}
                    className="w-full flex flex-col gap-y-5"
                  >
                    <div className="flex flex-col gap-y-4">
                      <div
                        className={clsx(
                          "w-full",
                          !!formStateSamlSSo?.errors?.email?.message
                            ? "flex flex-col gap-y-1"
                            : "block"
                        )}
                      >
                        <input
                          disabled={
                            loadingGoogle ||
                            loadingMicrosoft ||
                            sendingMagicLink
                          }
                          {...registerSamlSso("email")}
                          type="text"
                          placeholder="E-mail"
                          className={clsx(
                            `border disabled:opacity-50 rounded-full w-full text-base font-normal  py-[18px] px-5 outline-none`,
                            !!formStateSamlSSo?.errors?.email?.message
                              ? "border-[#ED1C24] text-[#ED1C24]"
                              : "border-[#C0B3C3] text-[#1D1D1D]"
                          )}
                        />
                        {formStateSamlSSo?.errors?.email?.message && (
                          <div className="flex items-center gap-x-2">
                            <WarningIcon />
                            <span className="text-[#ED1C24] font-normal text-sm leading-5">
                              {formStateSamlSSo?.errors?.email?.message}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <button
                      disabled={
                        loadingGoogle || loadingMicrosoft || sendingMagicLink
                      }
                      className="bg-[#5E37FF] disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
                      type={"submit"}
                    >
                      Sign In
                    </button>
                  </form>
                )}
              </>
            )}

            {continueWithSamlSSO && (
              <div className="max-w-[460px] w-full flex items-center justify-center">
                <button
                  className="font-semibold text-sm mt-[18px] text-[#1D1233]"
                  onClick={() => {
                    setContinueWithSamlSSO(false);
                  }}
                  type="button"
                >
                  Go Back
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex-1 min-h-screen relative overflow-hidden h-full">
        <video
          src="/sphere_side_t.mp4"
          width="100%"
          height="100%"
          className="absolute top-1/2 w-full min-h-screen transform -translate-y-1/2 -right-[0%]"
          loop
          autoPlay
          playsInline
          muted
        ></video>
      </div>
    </div>
  );
};

export default SignIn;
