import React, { useState } from "react";
import { z } from "zod";
import LogoVertical from "../assets/illustrations/LogoVertical";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { clsx } from "clsx";
import { Link } from "react-router-dom";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "Required" })
    .email({ message: "Invalid email" }),
});

const ForgotPassword = () => {
  const [sendPasswordResetEmail, sending, errorResetPassword] =
    useSendPasswordResetEmail(auth);

  const [hasSentEmail, setHasSentEmail] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmitSendForgotPassword = (data) => {
    sendPasswordResetEmail(data.email).then((approved) => {
      if (!!approved) {
        setHasSentEmail(true);
      }
    });
  };

  return (
    <div className="flex items-center gap-x-[60px] h-full w-full">
      <div className="flex-1 pl-[10%]">
        <div className="flex flex-col max-w-[460px] w-fulljustify-center items-center">
          <LogoVertical />
          <div className="mt-[60px] flex flex-col gap-y-3.5 text-[#1D1233] text-center">
            <h2 className="font-semibold text-[48px] leading-8">
              {hasSentEmail ? "Email has sent" : "Forgot password?"}
            </h2>
            <span className="font-normal text-base">
              {hasSentEmail
                ? "You will get an email with the reset instructions"
                : "Don't worry, we'll send you reset instructions."}
            </span>
          </div>
          {!hasSentEmail ? (
            <form
              onSubmit={handleSubmit(onSubmitSendForgotPassword)}
              className="w-full flex flex-col gap-y-5 mt-[56px]"
            >
              <div className="flex flex-col gap-y-4">
                <div
                  className={clsx(
                    "w-full",
                    !!errors?.email?.message ? "flex flex-col gap-y-1" : "block"
                  )}
                >
                  <input
                    disabled={sending}
                    {...register("email")}
                    type="text"
                    placeholder="E-mail"
                    className={clsx(
                      `border disabled:opacity-50 rounded-full w-full text-base font-normal text-[#1D1D1D] py-[18px] px-5 outline-none`,
                      !!errors?.email?.message
                        ? "border-red-400"
                        : "border-[#C0B3C3]"
                    )}
                  />
                  {(errors?.email?.message || errorResetPassword?.code) && (
                    <span className="text-red-400 font-semibold text-xs pl-3">
                      {errors?.email?.message ||
                        "Something went wrong, try again later"}
                    </span>
                  )}
                </div>
              </div>

              <button
                disabled={sending}
                className="bg-[#5E37FF] disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
                type="submit"
              >
                Reset password
              </button>
              <div className="text-[#898690] text-sm font-normal text-center">
                Back to{" "}
                <Link to="/" className="font-semibold text-[#1D1233]">
                  Log in
                </Link>
              </div>
            </form>
          ) : (
            <Link
              to="/"
              className="bg-[#5E37FF] w-full mt-[56px] disabled:opacity-50 py-[18px] rounded-full text-white text-[15px] font-semibold text-center"
            >
              Go back to log in
            </Link>
          )}
        </div>
      </div>
      <div className="flex-1 min-h-screen relative overflow-hidden h-full">
        <video
          src="/sphere_side_t.mp4"
          width="100%"
          height="100%"
          className="absolute top-1/2 w-full min-h-screen transform -translate-y-1/2 -right-[0%]"
          loop
          autoPlay
          playsInline
          muted
        ></video>
      </div>
    </div>
  );
};

export default ForgotPassword;
