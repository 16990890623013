import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useQuery } from "react-query";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { auth, firestore } from "./firebase";
import ChartsPage from "./pages/ChartsPage";
import ChatsPage from "./pages/ChatsPage";
import Dashboard from "./pages/Dashboard";
import IntroPage from "./pages/IntroPage";
import SettingsPage from "./pages/SettingsPage";
import SignIn from "./pages/SignIn";
import SlackBot from "./pages/SlackBot";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import AuthAction from "./pages/AuthAction";
import AdminPage from "./pages/AdminPage";
import CompanyDetailsPage from "./pages/CompanyDetailsPage";
import AccessControlPage from "./pages/AccessControlPage";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import {useEmailUUIDAuthState} from "./utils/hooks/useEmailUUIDAuthState";

function App() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const [userAuth, userLoading] = useAuthState(auth);
  const emailUUID = useEmailUUIDAuthState(auth);

  const {
    data: projectsData,
    isLoading: projectsLoading,
    refetch: refetchProjectData,
  } = useQuery(
    ["getProjects", emailUUID],
    async () => {
      const refProjects = query(
        collection(firestore, "projects"),
        where("admins", "array-contains", emailUUID)
      );

      const dataProjects = await getDocs(refProjects);

      return dataProjects.docs.map((i) => i.data());
    },
    { enabled: !!emailUUID, refetchOnWindowFocus: false }
  );

  console.log(emailUUID, "from app ");
  const PrivateRoutes = () => {
    return (
      !userLoading &&
      (userAuth ? (
        !projectsLoading &&
        (projectsData && !!projectsData.length ? (
          <Dashboard
            projectsData={projectsData}
            refetchProjectData={refetchProjectData}
          />
        ) : (
          <Navigate to={"/project"} replace />
        ))
      ) : (
        <Navigate to="/" reaplce />
      ))
    );
  };

  const PublicRoutes = () => {
    return (
      !userLoading &&
      (!userAuth ? (
        <Outlet />
      ) : (
        !projectsLoading &&
        (projectsData && !!projectsData.length ? (
          <>
            <Navigate to={`/project/${projectsData[0].id}/chats`} replace />
          </>
        ) : (
          <>
            <Navigate to={`/project`} replace />
          </>
        ))
      ))
    );
  };

  const IntroRoute = () => {
    return (
      !userLoading &&
      (!userAuth ? (
        <Navigate to={`/`} replace />
      ) : (
        !projectsLoading &&
        (projectsData && !!projectsData.length ? (
          <Navigate to={`/project/${projectsData[0].id}/chats`} replace />
        ) : (
          <IntroPage refetchProjectData={refetchProjectData} />
        ))
      ))
    );
  };

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<SignIn />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route path="/auth/action" element={<AuthAction />} />
        <Route path="/project" element={<IntroRoute />} />
        <Route path="/project/:projectId" element={<PrivateRoutes />}>
        <Route path="settings">
            <Route index element={<SettingsPage />} />
            <Route path="admins" element={<AdminPage />} />
            <Route path="access-control" element={<AccessControlPage />} />
            <Route path="company-details" element={<CompanyDetailsPage />} />
          </Route>
          <Route path="chats" element={<ChatsPage />} />
          <Route path="charts" element={<ChartsPage />} />
        </Route>

        <Route
          path={"/slackbot/:projectId"}
          element={
            <div className="min-h-screen bg-[#F5F6FB] h-full w-full max-w-[1800px] mx-auto">
              <SlackBot />
            </div>
          }
        />
      </Routes>
    </FirestoreProvider>
  );
}

export default App;
