import { Collapse, Tooltip } from "@mantine/core";
import { clsx } from "clsx";
import { useViewportSize } from "@mantine/hooks";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ChartsIcon from "../assets/icons/new/ChartIcon";
import ChatsIcon from "../assets/icons/new/ChatIcon";
import FilesIcon from "../assets/icons/new/FilesIcon";
import OpenChatIcon from "../assets/icons/new/OpenChatIcon";
import SettingsIcon from "../assets/icons/new/SettingsIcon";
import SettingsArrow from "../assets/icons/new/SettingsArrow";

const SidebarItems = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const { width } = useViewportSize();

  const instance = process.env.REACT_APP_FIREBASE_PROJECT_ID;

  return (
    <>
      {/* Files  */}
      {/* <Tooltip
        disabled={width > 1280}
        withArrow
        label="Files"
        arrowSize={12}
        radius="md"
        styles={{
          tooltip: {
            backgroundColor: "#5E37FF",
            fontWeight: 600,
            paddingBottom: "5px",
          },
          arrow: { backgroundColor: "#5E37FF" },
        }}
      >
        <Link
          to={`/project/${projectId}/files`}
          className={`${
            "files" === location.pathname.split("/")[3]
              ? "cs_lg:bg-[#FAF9FF] opacity-100 "
              : "bg-transparent opacity-40"
          } mx-auto cs_lg:mx-initial cs_lg:w-full flex items-center gap-x-2 px-5 py-3 rounded-lg`}
        >
          <div>
            <FilesIcon
              fill={`${
                location.pathname.split("/")[3] === "files"
                  ? "#5E37FF"
                  : "#1D1233"
              }`}
            />
          </div>
          <span
            className={`${
              "files" === location.pathname.split("/")[3]
                ? "text-[#5E37FF]"
                : "text-[#1D1233]"
            } font-semibold hidden cs_lg:block`}
          >
            Files
          </span>
        </Link>
      </Tooltip> */}

      {/* Chats  */}
      <Tooltip
        disabled={width > 1280}
        withArrow
        label="Chats"
        arrowSize={12}
        radius="md"
        styles={{
          tooltip: {
            backgroundColor: "#5E37FF",
            fontWeight: 600,
            paddingBottom: "5px",
          },
          arrow: { backgroundColor: "#5E37FF" },
        }}
      >
        <Link
          to={`/project/${projectId}/chats`}
          className={`${
            "chats" === location.pathname.split("/")[3]
              ? "cs_lg:bg-[#FAF9FF] opacity-100 "
              : "bg-transparent opacity-40"
          } mx-auto cs_lg:mx-initial cs_lg:w-full flex items-center gap-x-2 px-5 py-3 rounded-lg`}
        >
          <div>
            <ChatsIcon
              fill={`${
                location.pathname.split("/")[3] === "chats"
                  ? "#5E37FF"
                  : "#1D1233"
              }`}
            />
          </div>
          <span
            className={`${
              "chats" === location.pathname.split("/")[3]
                ? "text-[#5E37FF]"
                : "text-[#1D1233]"
            } font-semibold hidden cs_lg:block`}
          >
            Chats
          </span>
        </Link>
      </Tooltip>

      {/* Settings  */}
      <div>
        <Tooltip
          disabled={width > 1280}
          withArrow
          label="Settings"
          arrowSize={12}
          radius="md"
          styles={{
            tooltip: {
              backgroundColor: "#5E37FF",
              fontWeight: 600,
              paddingBottom: "5px",
            },
            arrow: { backgroundColor: "#5E37FF" },
          }}
        >
          <Link
            to={`/project/${projectId}/settings`}
            className={`${
              "settings" === location.pathname.split("/")[3]
                ? "cs_lg:bg-[#FAF9FF] opacity-100 "
                : "bg-transparent opacity-40"
            } mx-auto cs_lg:mx-initial cs_lg:w-full flex items-center justify-between gap-x-2 px-5 py-3 rounded-lg`}
          >
            <div className="flex gap-x-2 items-center">
              <div>
                <SettingsIcon
                  fill={`${
                    location.pathname.split("/")[3] === "settings"
                      ? "#5E37FF"
                      : "#1D1233"
                  }`}
                />
              </div>
              <span
                className={`${
                  "settings" === location.pathname.split("/")[3]
                    ? "text-[#5E37FF]"
                    : "text-[#1D1233]"
                } font-semibold hidden cs_lg:block`}
              >
                Settings
              </span>
            </div>
            <div>
              <SettingsArrow
                className={clsx(
                  location.pathname.split("/")[3] === "settings"
                    ? "rotate-0"
                    : "rotate-180",
                  "duration-300 transition-all"
                )}
              />
            </div>
          </Link>
        </Tooltip>
        <Collapse
          className="flex flex-col justify-center items-center cs_lg:block cs_lg:ml-[19%] pb-4 mt-[16px]"
          in={location.pathname.split("/")[3] === "settings"}
        >
          <div className="flex flex-col gap-y-4 items-center justify-center cs_lg:items-stretch">
            <Link
              to="settings/admins"
              className={clsx(
                location.pathname.split("/")[4] === "admins" && "bfl-active",
                location.pathname.split("/")[4] === "admins"
                  ? "text-[#5E37FF]"
                  : "text-[#A5A0AD]",
                "bfl bfl-one font-semibold text-center cs_lg:text-left"
              )}
            >
              Admins
            </Link>
            <Link
              to="settings/access-control"
              className={clsx(
                location.pathname.split("/")[4] === "access-control" &&
                  "bfl-active",
                location.pathname.split("/")[4] === "access-control"
                  ? "text-[#5E37FF]"
                  : "text-[#A5A0AD]",
                "bfl bfl-two font-semibold text-center cs_lg:text-left"
              )}
            >
              Access control
            </Link>
            <Link
              to="settings/company-details"
              className={clsx(
                location.pathname.split("/")[4] === "company-details" &&
                  "bfl-active",
                location.pathname.split("/")[4] === "company-details"
                  ? "text-[#5E37FF]"
                  : "text-[#A5A0AD]",
                "bfl bfl-three font-semibold text-center cs_lg:text-left"
              )}
            >
              Company details
            </Link>
          </div>
        </Collapse>
      </div>

      {/* Open Chat  */}
      <Tooltip
        disabled={width > 1280}
        withArrow
        label="Open Chat"
        arrowSize={12}
        radius="md"
        styles={{
          tooltip: {
            backgroundColor: "#5E37FF",
            fontWeight: 600,
            paddingBottom: "5px",
          },
          arrow: { backgroundColor: "#5E37FF" },
        }}
      >
        <Link
          to={`${
            instance === "gocascade-dev"
              ? `https://app.gocascade-dev.ai/${projectId}`
              : `https://app.gocascade.ai/${projectId}`
          }`}
          target="_blank"
          rel="noopener noreferrer"
          className={`bg-transparent opacity-40 mx-auto cs_lg:mx-initial cs_lg:w-full flex items-center gap-x-2 px-5 py-3 rounded-lg`}
        >
          <div>
            <OpenChatIcon fill={`#1D1233`} />
          </div>
          <span className={`text-[#1D1233] font-semibold hidden cs_lg:block`}>
            Open Chat
          </span>
        </Link>
      </Tooltip>

      {/* Charts  */}
      <Tooltip
        disabled={width > 1280}
        withArrow
        label="Metrics"
        arrowSize={12}
        radius="md"
        styles={{
          tooltip: {
            backgroundColor: "#5E37FF",
            fontWeight: 600,
            paddingBottom: "5px",
          },
          arrow: { backgroundColor: "#5E37FF" },
        }}
      >
        <Link
          to={`/project/${projectId}/charts`}
          className={`${
            "charts" === location.pathname.split("/")[3]
              ? "cs_lg:bg-[#FAF9FF] opacity-100 "
              : "bg-transparent opacity-40"
          } mx-auto cs_lg:mx-initial cs_lg:w-full flex items-center gap-x-2 px-5 py-3 rounded-lg`}
        >
          <div>
            <ChartsIcon
              fill={`${
                location.pathname.split("/")[3] === "charts"
                  ? "#5E37FF"
                  : "#1D1233"
              }`}
            />
          </div>
          <span
            className={`${
              "charts" === location.pathname.split("/")[3]
                ? "text-[#5E37FF]"
                : "text-[#1D1233]"
            } font-semibold hidden cs_lg:block`}
          >
            Metrics
          </span>
        </Link>
      </Tooltip>
    </>
  );
};

export default SidebarItems;
