import { Loader, Modal } from "@mantine/core";
import React, { useState } from "react";
import PlusIconBordered from "../../assets/icons/new/PlusIconBordered";
import z from "zod";
import WarningIcon from "../../assets/icons/new/WarningIcon";
import { useFunctionsCall } from "@react-query-firebase/functions";
import { functions } from "../../firebase";

const ModalAddAdmin = ({ opened, setOpened, projectId }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [inviteSent, setInviteSent] = useState(false);

  const onSendEmailInvitation = useFunctionsCall(
    functions,
    "project-sendEmailInvitationToken"
  );

  console.log("onSendEmailInvitation", onSendEmailInvitation);

  const handleAddAdmin = () => {
    if (onSendEmailInvitation.isLoading) return;

    setError("");

    try {
      const checker = z
        .string()
        .min(1, { message: "Required" })
        .email({ message: "Invalid email" })
        .parse(email);

      if (!checker) return;

      onSendEmailInvitation.mutate({
        email,
        projectId,
      });
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.issues[0].message);
      }
    }
  };

  const handleClose = () => {
    if (onSendEmailInvitation.isLoading) return;

    setEmail("");
    setError("");
    setOpened(false);

    // RESET AFTER 250ms OTHERWISE IT WILL SHOW FORM FOR HALF A SECOND
    setTimeout(() => {
      onSendEmailInvitation.reset();
    }, 250);
  };

  return (
    <Modal
      closeOnClickOutside={false}
      centered
      withCloseButton={false}
      opened={opened}
      size={560}
      radius="md"
    >
      <div className="w-full flex justify-end">
        <button
          onClick={handleClose}
          className="text-[#777281] text-[14px] text-right p-1 bg-transparent outline-none font-normal"
        >
          Close
        </button>
      </div>
      <div className="w-full flex-col flex items-center justify-center mt-6 px-[44px] pb-[44px]">
        <span className="text-[30px] font-semibold text-center w-full text-[#1D1233]">
          {onSendEmailInvitation.isSuccess ? "Email sent!" : "Add new admin"}
        </span>
        {onSendEmailInvitation.isSuccess ? (
          <div className="w-full mt-2 flex items-center justify-center">
            <span className="text-center">Email has been sent to {email}</span>
          </div>
        ) : (
          <div className="mt-10 w-full">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@example.com"
              className="border text-[#1D1233] text-base font-normal w-full outline-none border-[#C0BEC3] rounded-full bg-transparent py-4 px-5"
            />
            {!!error.length && (
              <div className="flex items-center gap-x-2 pt-2">
                <WarningIcon />

                <span className="text-[#ED1C24] font-normal text-sm leading-5">
                  {error}
                </span>
              </div>
            )}
            <button
              onClick={handleAddAdmin}
              disabled={onSendEmailInvitation.isLoading}
              className="mt-5 bg-[#5E37FF] text-white flex items-center justify-center gap-x-3 py-3 w-full rounded-full"
            >
              {onSendEmailInvitation.isLoading ? (
                <Loader size="sm" />
              ) : (
                <>
                  <span className="pt-1">Send invitation</span>
                  <PlusIconBordered />
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalAddAdmin;
