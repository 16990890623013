import { useState } from "react";
import { Accordion } from "@mantine/core";
import { object } from "zod";

const FeedbackMessageAccordion = ({ itemid, itemmsg }) => {
  const [value, setValue] = useState("");

  return (
    <Accordion
      value={value}
      onChange={setValue}
      styles={{
        label: {
          fontWeight: "600",
          padding: "0",
        },
        chevron: {
          fontWeight: "600",
          marginLeft: "8px",
          marginRight: "8px",
          position: "absolute",
          left: "162px",
        },
        content: {
          padding: "0",
        },
      }}
      className="w-auto p-0 m-0 border-none mt-4"
    >
      <Accordion.Item value={itemid} className="border-none">
        <Accordion.Control className="text-base font-semibold text-[#5E37FF] p-0 flex flex-row-reverse items-center justify-end relative">
          {value ? "Hide" : "View"} user feedback
        </Accordion.Control>
        <Accordion.Panel className="p-0">
          <div class="pt-4 text-[#1D1233] text-base leading-[26px]">
            "{itemmsg}"
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default FeedbackMessageAccordion;
