import { Collapse, Tooltip } from "@mantine/core";
import { useDisclosure, useViewportSize } from "@mantine/hooks";
import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowDown from "../assets/icons/ArrowDown";
import ArrowRight from "../assets/icons/new/ArrowRight";
import CascadeIconResponsive from "../assets/icons/new/CascadeIconResponsive";
import StackedProjects from "../assets/icons/new/StackedProjects";
import MainLogo from "../assets/illustrations/MainLogo";
import SidebarItems from "../components/SidebarItems";

const Sidebar = ({ projectsData, refetchProjectData }) => {
  const { projectId } = useParams();
  const [openedCollapse, toggleCollapse] = useDisclosure(false);
  const [openedProjects, toggleProjects] = useDisclosure(false);
  const [openedCollapseMobile, toggleCollapseMobile] = useDisclosure(false);

  const { width } = useViewportSize();

  const getActiveProjectData = useMemo(() => {
    return projectsData.filter((i) => i.id === projectId)[0];
  }, [projectId, projectsData]);

  const handleCollapse = () => {
    if (projectsData && projectsData?.length > 1) {
      toggleCollapse.toggle();
      return;
    }

    return;
  };

  const handleCollapseMobile = () => {
    if (projectsData && projectsData?.length > 1) {
      toggleCollapseMobile.toggle();

      return;
    }

    return;
  };

  return (
    <>
      <div
        className={`min-h-screen z-20 flex no-scrollbar flex-col pb-[120px] cs_lg:overflow-y-scroll max-h-screen h-full sticky top-0 left-0 bg-white w-[129px] cs_lg:w-[340px] basis-[129px] cs_lg:basis-[340px] shrink-0`}
      >
        {openedProjects && (
          <div className="absolute w-[292px] top-[127px] left-full z-50">
            <div
              className={`${
                openedCollapseMobile ? "pt-10 pb-2" : "pt-10 pb-10"
              } border border-[#E8E5F6] bg-white rounded-lg`}
            >
              <div className="flex items-center justify-between px-6">
                <span className="text-[#1D1233] text-sm">Your Project</span>
              </div>
              <div className="relative">
                <button
                  type="button"
                  onClick={handleCollapseMobile}
                  className="flex outline-none items-center w-full justify-between px-6 pt-5"
                >
                  <span className="text-[#1D1233] text-2xl font-semibold leading-[18px]">
                    {getActiveProjectData.name}
                  </span>
                  {projectsData && projectsData?.length > 1 && (
                    <ArrowDown
                      className={`transform duration-100 ${
                        openedCollapseMobile ? "rotate-180" : "rotate-0"
                      }`}
                      fill={"#777281"}
                      width="20"
                      height="21"
                    />
                  )}
                </button>
                <Collapse
                  className="mt-5"
                  animateOpacity={false}
                  in={openedCollapseMobile}
                >
                  <div className="flex flex-col overflow-y-auto h-[178px]">
                    {projectsData.map((item, index) => (
                      <Link
                        to={`/project/${item.id}/chats`}
                        key={index}
                        className={`font-semibold ${
                          item.id === getActiveProjectData.id
                            ? "text-[#5E37FF] bg-[#FAF9FF]"
                            : "text-[#1D1233]/40"
                        } text-base px-6 flex items-center border-t py-[10.5px] border-[#E8E5F6] justify-between`}
                      >
                        <span>{item.name}</span>
                        <ArrowRight
                          className={`-rotate-90 opacity-50`}
                          fill={
                            item.id === getActiveProjectData.id
                              ? "#5E37FF"
                              : "#A5A0AD"
                          }
                          width="20"
                          height="21"
                        />
                      </Link>
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 w-full flex flex-col">
          <div className="flex flex-col w-full flex-1">
            <div className="pt-12 pl-6 hidden cs_lg:block">
              <MainLogo />
            </div>
            <div className="pt-12 mx-auto block cs_lg:hidden">
              <CascadeIconResponsive />
            </div>

            <div className="mt-[48px] px-6 hidden cs_lg:block">
              <div
                className={`${
                  openedCollapse ? "pt-[39px] pb-2" : "pt-[39px] pb-[39px]"
                } border border-[#E8E5F6] rounded-lg`}
              >
                <div className="flex items-center justify-between px-6">
                  <span className="text-[#1D1233] text-sm">Your Project</span>
                </div>
                <div className="relative">
                  <button
                    type="button"
                    onClick={handleCollapse}
                    className="flex outline-none items-center w-full justify-between px-6"
                  >
                    <span className="text-[#1D1233] truncate text-2xl font-semibold">
                      {getActiveProjectData.name}
                    </span>
                    {projectsData && projectsData?.length > 1 && (
                      <ArrowDown
                        className={`transform duration-100 ${
                          openedCollapse ? "rotate-180" : "rotate-0"
                        }`}
                        fill={"#777281"}
                        width="20"
                        height="21"
                      />
                    )}
                  </button>
                  <Collapse animateOpacity={false} in={openedCollapse}>
                    <div className="flex flex-col mt-5">
                      {projectsData.map((item, index) => (
                        <Link
                          onClick={toggleCollapse.toggle}
                          to={`/project/${item.id}/chats`}
                          key={index}
                          className={`font-semibold ${
                            item.id === getActiveProjectData.id
                              ? "text-[#5E37FF] bg-[#FAF9FF]"
                              : "text-[#1D1233]/40"
                          } text-base px-6 flex items-center border-t py-[10.5px] border-[#E8E5F6] justify-between`}
                        >
                          <span className="truncate">{item.name}</span>
                          <ArrowRight
                            className={`-rotate-90 opacity-50`}
                            fill={
                              item.id === getActiveProjectData.id
                                ? "#5E37FF"
                                : "#A5A0AD"
                            }
                            width="20"
                            height="21"
                          />
                        </Link>
                      ))}
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>

            <Tooltip
              disabled={width > 1280}
              withArrow
              label="Projects"
              arrowSize={12}
              radius="md"
              styles={{
                tooltip: {
                  backgroundColor: "#5E37FF",
                  fontWeight: 600,
                  paddingBottom: "5px",
                },
                arrow: { backgroundColor: "#5E37FF" },
              }}
            >
              <button
                onClick={toggleProjects.toggle}
                type="button"
                className={`flex outline-none mt-[48px] mx-auto cs_lg:hidden w-[60px] h-[60px] ${
                  openedProjects ? "bg-[#1D1233]" : "bg-[#5E37FF]"
                } rounded-full items-center justify-center`}
              >
                <StackedProjects />
              </button>
            </Tooltip>
          </div>

          <div className="mt-[31px] px-6 flex flex-col gap-y-2">
            <SidebarItems />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
