import { Loader } from "@mantine/core";
import {useFirestoreDocumentMutation, useFirestoreQuery} from "@react-query-firebase/firestore";
import { collection, doc, query, where, limit, getDocs, addDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { firestore } from "../../../firebase";
import { useParams } from "react-router-dom";
import SelectSearch from "../../subcomponents/SelectSearch";

const WELCOME_MESSAGE_MAX_LENGTH = 1000;

const WelcomeMessageSettings = ({ projectData, setSucceed }) => {

  const { projectId } = useParams();
  const [chatMessage, setChatMessage] = useState("");
  const [isWelcomeMessageValid, setIsWelcomeMessageValid] = useState(true);
  const [regions, setRegions] = useState([{ label: "Default", value: 'default'}]);
  const [selectedRegion, setSelectedRegion] = useState('default');
  const [databaseEntry, setDatabaseEntry] = useState(null);

  const getWelcomeMessageSettings = async () => {
    const welcomeMessageSettingQuery = query(collection(firestore, `projects`, projectId, 'welcome_messages'),limit(1), where('tags', '==', [selectedRegion]));
    const results = await getDocs(welcomeMessageSettingQuery);
    const entries = results.docs.map((doc) => ({id: doc.id, ...doc.data()}));
    const welcomeMessageSetting = entries?.[0];
    if (welcomeMessageSetting) {
        setDatabaseEntry(welcomeMessageSetting);
    } else {
      setDatabaseEntry(null);
    }
  }

  useEffect(() => {
    if (databaseEntry) {
      setChatMessage(databaseEntry?.text || '');
    } else {
      setChatMessage(projectData.data().welcome_message);
    }
  }, [databaseEntry?.id])


  useEffect(() => {
    getWelcomeMessageSettings();
  }, [selectedRegion]);

  useEffect(() => {
    const available_regions = projectData.data()?.regions || {}
    setRegions([
      {
        label: "Default",
        value: 'default'
      },
      ...Object.keys(available_regions).map((region) => ({label: available_regions[region], value: region }))
    ])
  }, [projectData])

  // TEMP DATA

  // Mutation Project Prompt
  const mutation_projects = useFirestoreDocumentMutation(
    doc(collection(firestore, "projects"), projectId),
    { merge: true }
  );

  const mutation_welcome_message = useFirestoreDocumentMutation(
      doc(collection(firestore, "projects", projectId, 'welcome_messages'), databaseEntry?.id || 'a'),
      {merge: true}
  );

  const handleSaveTemplateQuestion = () => {
    if (mutation_projects.isLoading) return;
    if (mutation_welcome_message.isLoading) return;

    if (chatMessage.length > WELCOME_MESSAGE_MAX_LENGTH) {
      setIsWelcomeMessageValid(false);
    } else {
      setIsWelcomeMessageValid(true);

      if (selectedRegion === 'default') {
        mutation_projects.mutate({
          welcome_message: chatMessage,
        });
      }

      if (databaseEntry) {
        mutation_welcome_message.mutate({
          text: chatMessage,
        });
      } else {
        addDoc(collection(firestore, "projects", projectId, 'welcome_messages'), {
            text: chatMessage,
            tags: [selectedRegion]
        }).then(() => {
           getWelcomeMessageSettings();
        })
        //insert into collection the tag and the text
      }
      setSucceed(true);
    }
  };

  // Get Project Prompt
  useEffect(() => {
    if (projectData.data()) {
      let prompt = projectData.data().welcome_message;

      setChatMessage(prompt ?? "");
    }
  }, [projectData]);

  return (
    <div className="flex flex-col w-full">
      <span className="text-2xl font-semibold text-[#1D1233]">
        Chat welcome message
      </span>
      <span className="text-base font-normal text-[#1D1233] pt-2">
        Template question is the initial question chatbot starts with
      </span>
      <div className="bg-white rounded-lg w-full p-6 mt-[32px]">
        <div class="lg:max-w-lg mb-6">
          <h4 class="mb-4 font-semibold text-base">Select the region</h4>
          <SelectSearch label="" data={regions} value={selectedRegion} onChange={(_value) => setSelectedRegion(_value)}  />
        </div>

        <textarea
          onChange={(e) => setChatMessage(e.target.value)}
          value={chatMessage}
          spellCheck={false}
          className="border border-[#D9D6EA] outline-none resize-none w-full min-h-[192px] max-h-[192px] rounded-[4px] text-[#1D1233] text-base font-normal leading-6 py-[18px] px-6"
        />
        {!isWelcomeMessageValid ? (
          <div style={{ color: "red" }}>
            Welcome message can't exceed {WELCOME_MESSAGE_MAX_LENGTH} characters
          </div>
        ) : null}
        <div className="w-full flex items-center justify-center mt-5">
          <button
            type="button"
            onClick={handleSaveTemplateQuestion}
            disabled={mutation_projects.isLoading}
            className={`${
              mutation_projects.isLoading ? "opacity-60" : "opacity-100"
            } bg-[#2BCC58] flex items-center justify-center outline-none rounded-full text-white max-w-[304px] w-full py-[14.85px] px-5 font-semibold`}
          >
            {mutation_projects.isLoading ? (
              <Loader size={18} color="cyan" />
            ) : (
              <span className="pt-1">Save chat message</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessageSettings;
